import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useGetMember } from "@mbj-front-monorepo/data-access";
import { MbjFlex, MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { ProfilProCard } from "./ProfilProCard";
import { CollaborateursCard } from "./CollaborateursCard";
import { OffresCard } from "./OffresCard";

interface DashboardProCtrlProps {
  className?: string;
}

const DashboardProCtrl = ({ className }: DashboardProCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  return (
    <div className={className}>
      {MemberQuery.isLoading ? (
        <MbjLoading />
      ) : MemberQuery.isError ? (
        "Erreurs..."
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: MemberQuery.data?.name,
            }}
          />
          <MbjFlex>
            <ProfilProCard Member={MemberQuery.data} basis={"25%"} />
            <MbjFlex direction={"column"} basis={"70%"}>
              <CollaborateursCard to={"../collaborateurs"} />
              <OffresCard to={"../offres"} />
            </MbjFlex>
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const DashboardPro = styled(DashboardProCtrl)((props) => ({
  padding: "1% 2%",
}));
