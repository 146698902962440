import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useGetMember, useGetOffreInterne } from "@mbj-front-monorepo/data-access";
import { MbjLoading, MbjNavPath, MbjTabs, MbjTabsContent, MbjTabsList, MbjTabsTrigger } from "@mbj-front-monorepo/ui";
import { EditionOffre } from "./EditionOffre";
import { ProxiProfilsMain } from "./ProxiProfils/ProxiProfilsMain";

interface OffresConsultProCtrlProps {
  className?: string;
}

const OffresConsultProCtrl = ({ className }: OffresConsultProCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const { idOffre } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  const OffreInterneQuery = useGetOffreInterne(idOffre ? parseInt(idOffre) : undefined);

  return (
    <div className={className}>
      {MemberQuery.isLoading || OffreInterneQuery.isLoading ? (
        <MbjLoading />
      ) : MemberQuery.isError || OffreInterneQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: MemberQuery.data?.name,
              },
              { index: 3, label: "Offres" },
              { index: 4, label: OffreInterneQuery.data?.libelle },
            ]}
          />
          <MbjTabs defaultValue="tab1" orientation={"vertical"}>
            <MbjTabsList aria-label="Gérer l'offre">
              <MbjTabsTrigger value="tab1">Edition</MbjTabsTrigger>
              <MbjTabsTrigger value="tab2">Profils compatibles</MbjTabsTrigger>
            </MbjTabsList>
            <MbjTabsContent value="tab1" $noPadding>
              <EditionOffre OffreInterne={OffreInterneQuery.data} />
            </MbjTabsContent>
            <MbjTabsContent value="tab2" $noPadding>
              <ProxiProfilsMain />
            </MbjTabsContent>
          </MbjTabs>
        </>
      )}
    </div>
  );
};

export const OffresConsultPro = styled(OffresConsultProCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",
}));
