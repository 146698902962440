import {
  MbjButton,
  MbjFlex,
  MbjHeading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import React from "react";
import { Operation, useAddPropositionOffre } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import { GetElementType } from "@mbj-front-monorepo/utils";
import styled from "styled-components";

interface ProposeOffrePeModalCtrlProps extends MbjModalCtrlProps {
  OffrePE?: GetElementType<Operation<"getOffrePECollection">>;

  particulierId: number;
}

export const ProposeOffrePeModalCtrl = (props: ProposeOffrePeModalCtrlProps) => {
  const mutationAdd = useAddPropositionOffre();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  if (!props.OffrePE) {
    return null;
  }

  return (
    <MbjModal title={"Proposition d'offre"} {...props}>
      <MbjFlex direction={"column"} gap={"15px"}>
        <MbjHeading level={"h1"}>
          {props.OffrePE.libelle} - {props.OffrePE.metier?.libelle}
        </MbjHeading>
        <form
          onSubmit={handleSubmit((data) => {
            const dataToSend = {
              ...data,
              Particulier: `/api/particuliers/${props.particulierId}`,
              link: props.OffrePE?.path,
            };
            console.log(dataToSend);
            mutationAdd.mutate(dataToSend);
          })}
        >
          <MbjFlex direction={"column"} gap={"10px"}>
            <MbjSimpleInputContainer errors={errors} label={"Commentaire"}>
              <MbjSimpleTextArea name={"commentairesConseiller"} id={"commentairesConseiller"} register={register} />
            </MbjSimpleInputContainer>
            <MbjButton type={"submit"} alignSelf={"flex-end"} isPending={mutationAdd.isLoading}>
              Proposer l'offre
            </MbjButton>
          </MbjFlex>
        </form>
      </MbjFlex>
    </MbjModal>
  );
};

export const ProposeOffrePeModal = styled(ProposeOffrePeModalCtrl)((props) => ({}));
