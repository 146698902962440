import { useGetConseillerMe } from "../conseiller";
import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetPortefeuille = () => {
  const ConseillerQuery = useGetConseillerMe();
  const idConseiller = ConseillerQuery.data?.id;
  const promise = () => fetchApi(`/api/portefeuilles`, { method: "get", query: { Conseiller: idConseiller + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["portefeuilles", idConseiller], promise, {
    enabled: !!idConseiller,
  });
};

export const useGetIfJoinPortefeuille = (code: string | undefined) => {
  const promise = () => fetchApi("/api/portefeuilles/join_wallet", { method: "get", query: { Code: code + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["portefeuille", code], promise, {
    enabled: !!code,
  });
};

export const useAcceptJoin = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putPortefeuilleItem", "requestBody">) => {
      return fetchApi("/api/portefeuilles/{id}", {
        method: "put",
        json: data,
        params: { id: id + "" },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["portefeuilles"]);
        queryClient.invalidateQueries(["portefeuille"]);
        toast.success("Invitation  envoyée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
