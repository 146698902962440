import React from "react";
import styled from "styled-components";
import { useGetOnePartMob, useGetParticulier } from "@mbj-front-monorepo/data-access";
import { useLocation, useParams } from "react-router-dom";
import { MbjFlex, MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { MbjCompareMobilites, MbjCompareReferentiel } from "@mbj-front-monorepo/data-ui";

interface MobiliteDetailsCtrlProps {
  className?: string;
}

const MobiliteDetailsCtrl = (props: MobiliteDetailsCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const { idMobPart } = useParams();
  if (!id || !idMobPart) {
    throw new Error("id or idMetier is undefined");
  }
  const ParticulierMobQuery = useGetOnePartMob(
    idMobPart ? parseInt(idMobPart) : undefined,
    id ? parseInt(id) : undefined
  );
  const ParticulierQuery = useGetParticulier(id ? parseInt(id) : undefined);

  return (
    <div className={props.className}>
      {ParticulierQuery.isLoading || ParticulierMobQuery.isLoading ? (
        <MbjLoading />
      ) : ParticulierQuery.isError || ParticulierMobQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: ParticulierQuery.data?.nom?.toUpperCase() + " " + ParticulierQuery.data?.prenom,
              },
              { index: 3, label: "Mobilités" },
              { index: 4, label: ParticulierMobQuery.data?.Metier?.libelle?.toUpperCase() },
            ]}
          />
          <MbjFlex direction={"column"} basis={"100%"}>
            <h1>Mobilité vers : {ParticulierMobQuery.data?.Metier?.libelle}</h1>
            <MbjCompareReferentiel
              idToCompare1={ParticulierQuery.data.id || -1}
              idToCompare2={ParticulierMobQuery.data?.Metier?.id || -1}
              typeCompared={"PM"}
            />
            {ParticulierMobQuery.data && ParticulierMobQuery.data.Metier && (
              <MbjCompareMobilites Metier={ParticulierMobQuery.data.Metier} idParticulier={ParticulierQuery.data.id} />
            )}
            {/*<FormationsToWidget employeeId={employeeQuery.data.id} metierId={metierQuery.data.id} />*/}
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const MobiliteDetails = styled(MobiliteDetailsCtrl)((props) => ({
  padding: "1% 2%",
  h1: {
    fontSize: "24px",
    paddingLeft: "0.5rem",
    margin: "15px auto 20px 0",
  },
}));
