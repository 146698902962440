import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GiFactory } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { MbjLinkBox, MbjLinkOverlay } from "@mbj-front-monorepo/ui";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface MbjEntrepriseSuivieCardCtrlProps {
  className?: string;
  EntrepriseSuivie: GetElementType<Operation<"getPortefeuilleProCollection">>;
}

const MbjEntrepriseSuivieCardCtrl = ({ className, EntrepriseSuivie }: MbjEntrepriseSuivieCardCtrlProps) => {
  const navigate = useNavigate();

  return (
    <MbjLinkBox className={`EntrepriseSuivie ${className}`}>
      <div className={`card-in`}>
        <div className={`head-card`}>
          <MbjLinkOverlay className={`name-place`} to={EntrepriseSuivie.Member?.id?.toString() || "0"}>
            {EntrepriseSuivie.Member?.name}
          </MbjLinkOverlay>
          <div className={`roundAvatar`}>
            {EntrepriseSuivie.Member && EntrepriseSuivie.Member.logoUrl ? (
              <img src={`${process.env.REACT_APP_API_URL_ROOT}${EntrepriseSuivie.Member.logoUrl}`} />
            ) : (
              <GiFactory />
            )}
          </div>
        </div>
        <div className={`body-card`}>
          <p>
            Ajouté le{" "}
            {EntrepriseSuivie.acceptAt ? new Date(EntrepriseSuivie.acceptAt).toLocaleDateString().slice(0, 10) : ""}
          </p>
        </div>
      </div>
    </MbjLinkBox>
  );
};

export const EntrepriseSuivieCard = styled(MbjEntrepriseSuivieCardCtrl)((props) => ({
  flexBasis: "46%",
  margin: "0.5rem",
  "&:hover": {
    cursor: "pointer",
    ".card-in": {
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
    },
  },
  ".card-in": {
    background: "white",
    borderRadius: "4px",
    ".head-card": {
      display: "flex",
      justifyContent: "space-between",
      background: props.theme.primaryLighten,
      padding: "0.25rem",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      fontWeight: "bold",
      ".roundAvatar": {
        background: "white",
        borderRadius: "50%",
        overflow: "hidden",
        width: "30px",
        height: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        img: {
          width: "100%",
          height: "auto",
        },
        svg: {
          fontSize: "22px",
          margin: "auto",
        },
      },
    },
    ".body-card": {
      padding: "0.5rem",
      fontSize: "calc(14px + 0.2rem)",
    },
  },
}));
