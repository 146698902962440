import { MbjLoading, MbjNavPath, MbjTabs, MbjTabsContent, MbjTabsList, MbjTabsTrigger } from "@mbj-front-monorepo/ui";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetParticulier } from "@mbj-front-monorepo/data-access";
import styled from "styled-components";
import { OffresPE } from "./OffresPE/OffresPE";
import { OffresInternes } from "./OffresInternes/OffresInternes";

interface OffresCtrlProps {
  className?: string;
}

const OffresCtrl = (props: OffresCtrlProps) => {
  const { id } = useParams();
  const location = useLocation();
  const particulierQuery = useGetParticulier(id ? parseInt(id) : undefined);

  return (
    <div className={props.className}>
      {particulierQuery.isLoading ? (
        <MbjLoading />
      ) : particulierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
              },
              { index: 3, label: "Offres" },
            ]}
          />
          <MbjTabs defaultValue="tab1" orientation={"vertical"}>
            <MbjTabsList aria-label="Manage your account">
              <MbjTabsTrigger value="tab1">Pôle emploi</MbjTabsTrigger>
              <MbjTabsTrigger value="tab2">Offres internes</MbjTabsTrigger>
            </MbjTabsList>
            <MbjTabsContent value="tab1" $noPadding>
              <OffresPE particulierId={particulierQuery.data.id || -1} />
            </MbjTabsContent>
            <MbjTabsContent value="tab2" $noPadding>
              <OffresInternes particulierId={particulierQuery.data.id || -1} />
            </MbjTabsContent>
          </MbjTabs>
        </>
      )}
    </div>
  );
};

export const Offres = styled(OffresCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",
}));
