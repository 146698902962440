import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import {
  MbjButton,
  MbjEmailSimpleInput,
  MbjFlex,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { useAddParticulier } from "@mbj-front-monorepo/data-access";

interface FormNewInvitationCtrlProps {
  className?: string;
}

const FormNewInvitationCtrl = ({ className }: FormNewInvitationCtrlProps) => {
  const mutation = useAddParticulier();

  const methods = useForm({
    mode: "onChange",
  });
  const onSubmit = (data: any) => {
    console.log(data);
    mutation.mutate(data);
  };
  return (
    <div className={className}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="Infos">
        <MbjFlex direction={"column"} gap={"15px"}>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom *"}>
            <MbjSimpleInput
              name={"nom"}
              id={"nom"}
              noPadding
              register={methods.register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom requis",
                },
              }}
            />
          </MbjSimpleInputContainer>

          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom *"}>
            <MbjSimpleInput
              name={"prenom"}
              id={"prenom"}
              noPadding
              register={methods.register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Prénom requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Email *"}>
            <MbjEmailSimpleInput
              name={"email"}
              id={"email"}
              noPadding
              register={methods.register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Email requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
            Confirmer
          </MbjButton>
        </MbjFlex>
      </form>
    </div>
  );
};

export const FormNewInvitation = styled(FormNewInvitationCtrl)((props) => ({}));
