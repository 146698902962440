import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { BiUser } from "react-icons/bi";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";

interface PersonneWaitCtrlProps {
  className?: string;
  Portefeuille: GetElementType<Operation<"getPortefeuilleCollection">>;
}

const PersonneWaitCtrl = ({ className, Portefeuille }: PersonneWaitCtrlProps) => {
  return (
    <div className={`portefeuilleWait ${className}`}>
      <div className={`card-in`}>
        <div className={`head-card`}>
          <div className={`name-place`}>
            {Portefeuille.Particulier?.prenom} {Portefeuille.Particulier?.nom}
          </div>
          <div className={`roundAvatar`}>
            <BiUser />
          </div>
        </div>
        <div className={`body-card`}>
          <p>Demandé le {Portefeuille.invitationAt ? getDateFR(new Date(Portefeuille.invitationAt)) : ""}</p>
        </div>
      </div>
    </div>
  );
};

export const PersonneWait = styled(PersonneWaitCtrl)((props) => ({
  width: "50%",
  padding: "0.5rem",
  ".card-in": {
    background: "white",
    borderRadius: 4,
    ".head-card": {
      background: props.theme.complementaryLighten,
      padding: "0.25rem",
      position: "relative",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      fontWeight: "bold",
      ".roundAvatar": {
        position: "absolute",
        background: "white",
        right: 10,
        top: "100%",
        transform: "translateY(-50%)",
        borderRadius: "50%",
        overflow: "hidden",
        width: 30,
        height: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        svg: {
          fontSize: 22,
          margin: "auto",
        },
      },
    },
    ".body-card": {
      padding: "0.5rem",
      fontSize: "calc(14px + 0.2rem)",
    },
  },
}));
