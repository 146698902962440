import React from "react";
import { Route, Routes } from "react-router-dom";
import { InternDesktopLayout } from "@mbj-front-monorepo/ui";
import { NotFound } from "../NotFound/NotFound";
import { MesEntreprisesSuivies } from "./MesEntreprisesSuivies/MesEntreprisesSuivies";
import { EntrepriseSuiviesDetails } from "./EntrepriseSuivieDetails/EntrepriseSuiviesDetails";

export const EntreprisesSuivies = () => {
  return (
    <div className="PortefeuillePro">
      <Routes>
        <Route element={<InternDesktopLayout type="Conseiller" />}>
          <Route path="" element={<MesEntreprisesSuivies />} />
        </Route>
        <Route path="/:id/*" element={<EntrepriseSuiviesDetails />} />
        <Route path="*" element={<NotFound infos={"ici"} />} />
      </Routes>
    </div>
  );
};
