import React from "react";
import styled from "styled-components";

import { useLocation, useParams } from "react-router-dom";
import { MbjIconButton, MbjLoading, MbjModal, MbjNavPath, MbjWidgetClassic, useModal } from "@mbj-front-monorepo/ui";
import { useGetFiPart, useGetParticulier, useGetXpPart } from "@mbj-front-monorepo/data-access";
import { GrAdd } from "react-icons/gr";
import {
  MbjParticulierFiCard,
  MbjParticulierFiNewForm,
  MbjParticulierXpCard,
  MbjParticulierXpNewForm,
} from "@mbj-front-monorepo/data-ui";

interface CVPartCtrlProps {
  className?: string;
}

const CVPartCtrl = ({ className }: CVPartCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const { isShowing, toggle } = useModal();
  const { isShowing: showNewFi, toggle: toggleNewFi } = useModal();
  const ParticulierQuery = useGetParticulier(id ? parseInt(id) : undefined);
  const XpQuery = useGetXpPart(id ? parseInt(id) : undefined);
  const FiQuery = useGetFiPart(id ? parseInt(id) : undefined);
  return (
    <div className={`partDash ${className}`}>
      {ParticulierQuery.isLoading ? (
        <MbjLoading />
      ) : ParticulierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: ParticulierQuery.data?.nom?.toUpperCase() + " " + ParticulierQuery.data?.prenom,
            }}
          />
          <MbjWidgetClassic title={"Expériences professionnelles"} bodyDirection={"column"}>
            <MbjIconButton
              onClick={toggle}
              ariaLabel={"Ajouter une expérience"}
              icon={<GrAdd />}
              themeColor={"third"}
              alignSelf={"flex-end"}
              isRound
            />
            <div className={`wrap-xps-in`}>
              {XpQuery.isLoading ? (
                <MbjLoading />
              ) : XpQuery.isError ? (
                "error..."
              ) : XpQuery.data.length === 0 ? (
                "Aucune expérience pour le moment"
              ) : (
                XpQuery.data.map((Xp, idx) => <MbjParticulierXpCard ParticulierXp={Xp} key={Xp.id} />)
              )}
            </div>
          </MbjWidgetClassic>
          <MbjWidgetClassic title={"Formations initiales"} bodyDirection={"column"}>
            <MbjIconButton
              onClick={toggleNewFi}
              ariaLabel={"Ajouter une formation initiale"}
              icon={<GrAdd />}
              themeColor={"third"}
              alignSelf={"flex-end"}
              isRound
            />
            <div>
              {FiQuery.isLoading ? (
                <MbjLoading />
              ) : FiQuery.isError ? (
                "error..."
              ) : FiQuery.data.length === 0 ? (
                "Aucune formation pour le moment"
              ) : (
                FiQuery.data.map((Fi, idx) => <MbjParticulierFiCard ParticulierFi={Fi} key={Fi.id} />)
              )}
            </div>
          </MbjWidgetClassic>
        </>
      )}
      {id && (
        <>
          <MbjModal isShowing={isShowing} hide={toggle} fitContent title="Nouvelle expérience professionnelle">
            <MbjParticulierXpNewForm idParticulier={parseInt(id.toString())} hideModal={toggle} />
          </MbjModal>
          <MbjModal isShowing={showNewFi} hide={toggleNewFi} fitContent title="Nouvelle formation initiale">
            <MbjParticulierFiNewForm idParticulier={parseInt(id.toString())} hideModal={toggleNewFi} />
          </MbjModal>
        </>
      )}
    </div>
  );
};

export const CVPart = styled(CVPartCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
}));
