import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuth } from "@mbj-front-monorepo/data-access";

interface PrivateRouteCtrlProps {
  from: string;
}

export function PrivateRouteCtrl(props: PrivateRouteCtrlProps) {
  const location = useLocation();
  //console.log("location from : ", location);
  return isAuth() ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname }} />;
}
