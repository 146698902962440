import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { MbjNavPath } from "@mbj-front-monorepo/ui";
import { MbjParticulierInformationsCard } from "@mbj-front-monorepo/data-ui";

interface InformationsPartCtrlProps {
  className?: string;
}

const InformationsPartCtrl = (props: InformationsPartCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();

  return (
    <div className={props.className}>
      <MbjNavPath routePath={location.pathname} />

      <MbjParticulierInformationsCard particulierId={id ? parseInt(id) : -1} isEditable={false} />
    </div>
  );
};

export const InformationsPart = styled(InformationsPartCtrl)((props) => ({
  padding: "1% 2%",
}));
