import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApi, Operation } from "../../services/fetchApi";
import { toast } from "react-toastify";

export const useGetConseillerMe = () => {
  const promise = () => fetchApi("/api/conseiller/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["conseiller", "me"], promise);
};

export const useMutateConseiller = () => {
  const queryClient = useQueryClient();
  const conseillerQuery = useGetConseillerMe();

  return useMutation(
    (data: Operation<"putConseillerItem", "requestBody">) => {
      return fetchApi("/api/conseillers/{id}", {
        method: "put",
        params: { id: conseillerQuery.data?.id + "" },
        json: data,
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(["conseiller", "me"], newData);
        toast.success("Profil modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
