import React from "react";
import {
  BaseColorsTheme,
  MbjAccordionContent,
  MbjAccordionTrigger,
  MbjLoading,
  useModal,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { Operation, useDeleteActivite, useUpdateNiveauActivite } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { MbjLineActivite } from "../../../atoms/MbjLineActivite/MbjLineActivite";

interface MbjEmployeeLineCompetenceCtrlProps {
  className?: string;
  themeColor?: BaseColorsTheme;
  CompetencesEmployee: GetElementType<Operation<"getEmployeeCompetenceCollection">>;
  idEmployee: number;
  isLoading: boolean;
  isError: boolean;
  EmployeeActivites?: GetElementType<Operation<"getEmployeeCompetenceCollection">>["EmployeeActivites"];

  isEditable?: boolean;
}

const MbjEmployeeLineCompetenceCtrl = (props: MbjEmployeeLineCompetenceCtrlProps) => {
  const mutationActivite = useUpdateNiveauActivite(props.idEmployee);
  const mutationDeleteActivite = useDeleteActivite(props.idEmployee);
  const modalTrash = useModal();

  return (
    <React.Fragment>
      <MbjAccordionTrigger themeColor={props.themeColor}>
        <div className="accordion-trigger-content">
          <span>{props.CompetencesEmployee.Competence?.libelle}</span>
        </div>
      </MbjAccordionTrigger>
      <MbjAccordionContent>
        <div className={props.className}>
          {props.isLoading ? (
            <MbjLoading />
          ) : props.isError ? (
            "Errors"
          ) : (
            props.EmployeeActivites?.map((activiteEmployee, idx) => {
              const handleTrash = () => {
                mutationDeleteActivite.mutate(activiteEmployee.id || -1, {
                  onSettled: () => {
                    modalTrash.toggle();
                  },
                });
              };
              return (
                <MbjLineActivite
                  isEditable={props.isEditable}
                  niveau={activiteEmployee.niveau || 0}
                  libelle={activiteEmployee.Activite?.libelle || ""}
                  changeLevelFunction={(level) =>
                    mutationActivite.mutate({ idEmployeeActivite: activiteEmployee.id || -1, niveau: level })
                  }
                  trashFunction={handleTrash}
                  modalTrash={modalTrash}
                  trashLoading={mutationDeleteActivite.isLoading}
                  key={idx}
                />
              );
            })
          )}
        </div>
      </MbjAccordionContent>
    </React.Fragment>
  );
};

export const MbjEmployeeLineCompetence = styled(MbjEmployeeLineCompetenceCtrl)((props) => ({
  padding: "10px",
  gap: "10px",
  display: "flex",
  flexDirection: "column",
}));
MbjEmployeeLineCompetence.defaultProps = {
  themeColor: "grey",
  isEditable: true,
};
