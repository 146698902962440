import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useGetEmployeesByMember, useGetMember } from "@mbj-front-monorepo/data-access";
import { MbjLink, MbjLoading, MbjNavPath, MbjScrollArea, MbjTableV2, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";

interface CollaborateursCtrlProps {
  className?: string;
}

const CollaborateursCtrl = ({ className }: CollaborateursCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  const EmployeesQuery = useGetEmployeesByMember(id ? parseInt(id) : undefined);

  const columnHelper = createColumnHelper<GetElementType<typeof EmployeesQuery.data>>();
  const columns = [
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.nom, {
          header: "Nom",
          cell: (info) => <MbjLink to={info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
        }),
        columnHelper.accessor((row) => row.prenom, {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Informations liées au poste",
      columns: [
        columnHelper.accessor((row) => row.UserMetier?.namePerso || "--", {
          header: "Poste",
        }),
        columnHelper.accessor((row) => row.Service?.libelle || "--", {
          header: "Service",
        }),
        columnHelper.accessor((row) => row.salary, {
          header: "Salaire",
          filterFn: "inNumberRange",
          cell: (info) => (info.getValue() ? info.getValue() + " €" : "--"),
        }),
        columnHelper.accessor((row) => row.entryAt, {
          header: "Date d'entrée",
          cell: (info) => {
            const value = info.getValue();
            return value ? getDateFR(new Date(value)) : "--";
          },
        }),
      ],
    }),
  ];

  const table = useReactTable({
    data: EmployeesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
  });

  return (
    <div className={className}>
      <MbjNavPath
        routePath={location.pathname}
        changeLabelTab={[
          {
            index: 2,
            label: MemberQuery.data?.name,
          },
          { index: 3, label: "Collaborateurs" },
        ]}
      />
      <MbjWidgetClassic title="Collaborateurs" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {EmployeesQuery.isLoading ? (
          <MbjLoading />
        ) : EmployeesQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <div>{table.getRowModel().rows.length} résultats</div>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} />
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
    </div>
  );
};

export const Collaborateurs = styled(CollaborateursCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",
}));
