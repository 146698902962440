import React, { useMemo } from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjFlex,
  MbjIconButton,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useParams } from "react-router-dom";
import { useGetPartMob } from "@mbj-front-monorepo/data-access";
import { MbjMobiliteListItem } from "@mbj-front-monorepo/data-ui";
import { FiRefreshCw } from "react-icons/fi";
import { getDateTimeFR } from "@mbj-front-monorepo/utils";

interface MobilitesByMbjCardCtrlProps extends MbjCardClassicCtrlProps {
  lastUpdate?: string;
}

const limit = 0.8;

const MobilitesByMbjCardCtrl = (props: MobilitesByMbjCardCtrlProps) => {
  const { id } = useParams();
  const mobilitesQuery = useGetPartMob(id ? parseInt(id) : -1);

  const mobilitesFiltered = useMemo(() => {
    if (mobilitesQuery.isError || mobilitesQuery.isLoading || !mobilitesQuery.data) {
      return [];
    } else {
      return mobilitesQuery.data.filter((m) => m.score && m.score > limit);
    }
  }, [mobilitesQuery.data, mobilitesQuery.isLoading, mobilitesQuery.isError, limit]);

  return (
    <MbjCardClassic title="Mobilités les plus favorables selon My-Better-Job" noPadding {...props}>
      <MbjFlex direction={"column"} height={"100%"} gap={"0px"}>
        <MbjFlex className={"top"} justify={"space-between"} align={"center"}>
          <span>
            {props.lastUpdate
              ? `Dernière mise à jour le ${getDateTimeFR(new Date(props.lastUpdate))}`
              : "Jamais calculé"}
          </span>
          <MbjIconButton
            size={"sm"}
            onClick={(e) => console.log("refresh")}
            icon={<FiRefreshCw />}
            ariaLabel={"Raffraichir les mobilités"}
          />
        </MbjFlex>
        <MbjScrollArea grow={1}>
          {mobilitesQuery.isLoading ? (
            <MbjLoading />
          ) : mobilitesQuery.isError ? (
            "Errors"
          ) : (
            mobilitesFiltered
              .sort(function (a, b) {
                return (b.score || 0) - (a.score || 0);
              })
              .map((mobilite, idx) => (
                <MbjListItem key={mobilite.id} to={`${mobilite.id}`}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          )}
        </MbjScrollArea>
      </MbjFlex>
    </MbjCardClassic>
  );
};

export const MobilitesByMbjCard = styled(MobilitesByMbjCardCtrl)((props) => ({
  ".top": {
    padding: "5px",
    borderBottom: `1px solid ${props.theme.primaryLighten}`,
  },
}));
