import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useGetParticulier } from "@mbj-front-monorepo/data-access";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { FaUserCircle } from "react-icons/fa";
import { DashboardPart } from "./Dashboard/DashboardPart";
import { InformationsPart } from "./Informations/InformationsPart";
import { CompetencesPart } from "./Competences/CompetencesPart";
import { NotFound } from "../../NotFound/NotFound";
import { Mobilites } from "./Mobilites/Mobilites";
import { CVPart } from "./CV/CVPart";
import { MobiliteDetails } from "./Mobilites/MobiliteDetails";
import styled from "styled-components";
import { Offres } from "./Offres/Offres";

interface PersonneSuivieDetailsCtrlProps {
  className?: string;
}

const PersonneSuivieDetailsCtrl = ({ className }: PersonneSuivieDetailsCtrlProps) => {
  const { id } = useParams();
  const ParticulierQuery = useGetParticulier(id ? parseInt(id) : undefined);
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Conseiller"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {ParticulierQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading level={"h4"} marginIn={"0"} themeColor={"primary"}>
                          {ParticulierQuery.data?.nom?.toUpperCase() + " " + ParticulierQuery.data?.prenom}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <Route path="" element={<Navigate to="dashboard" />} />
                <MbjLink to="dashboard">Tableau de bord</MbjLink>
                <MbjLink to="informations">Informations</MbjLink>
                <MbjLink to="cv">CV</MbjLink>
                <MbjLink to="competences">Compétences</MbjLink>
                <MbjLink to="mobilites">Mobilités</MbjLink>
                <MbjLink to="offres_compatibles">Offres compatibles</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<DashboardPart />} />
        <Route path="/informations" element={<InformationsPart />} />
        <Route path="/competences" element={<CompetencesPart />} />
        <Route path="/cv" element={<CVPart />} />
        <Route path="/mobilites" element={<Mobilites />} />
        <Route path="/offres_compatibles" element={<Offres />} />
        <Route path="/mobilites/:idMobPart" element={<MobiliteDetails />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const PersonneSuivieDetails = styled(PersonneSuivieDetailsCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",

  ".custom-content-menu": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",

    ".icon-profile-menu": {
      fill: props.theme.primaryDarken,
      height: "25px",
      width: "25px",
    },
  },
}));
