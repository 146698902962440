import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { NotFound } from "../../NotFound/NotFound";
import styled from "styled-components";
import { useGetMember } from "@mbj-front-monorepo/data-access";
import { DashboardPro } from "./Dashboard/DashboardPro";
import { Collaborateurs } from "./Collaborateurs/Collaborateurs";
import { OffresPro } from "./Offres/OffresPro";
import { OffresConsultPro } from "./Offres/DetailOffre/OffresConsultPro";
import { GiFactory } from "react-icons/gi";
import { CollaborateurDetail } from "./Collaborateurs/CollaborateurDetail";

interface PortefeuilleProDetailsCtrlProps {
  className?: string;
}

const PortefeuilleProDetailsCtrl = ({ className }: PortefeuilleProDetailsCtrlProps) => {
  const { id } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Conseiller"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {MemberQuery.data ? (
                      <>
                        <GiFactory className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level="h1">
                          {MemberQuery.data.name}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <Route path="" element={<Navigate to="dashboard" />} />
                <MbjLink to="dashboard">Tableau de bord</MbjLink>
                <MbjLink to="collaborateurs">Collaborateurs</MbjLink>
                <MbjLink to="offres">Offres</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<DashboardPro />} />
        <Route path="/collaborateurs" element={<Collaborateurs />} />
        <Route path="/collaborateurs/:idCollab" element={<CollaborateurDetail />} />
        <Route path="/offres" element={<OffresPro />} />
        <Route path="/offres/:idOffre" element={<OffresConsultPro />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const EntrepriseSuiviesDetails = styled(PortefeuilleProDetailsCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",

  ".custom-content-menu": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",

    ".icon-profile-menu": {
      fill: props.theme.primaryDarken,
      height: "25px",
      width: "25px",
    },
  },
}));
