import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { MbjRangeStatic, MbjRangeStaticCtrlProps } from "../../atoms/MbjRangeStatic/MbjRangeStatic";
import { RiAddFill, RiSubtractFill } from "react-icons/ri";
import { useDebounce, useHover } from "@mbj-front-monorepo/utils";
import { MbjIconButton } from "../../atoms/MbjButton/MbjIconButton";

interface MbjRangeCtrlProps extends MbjRangeStaticCtrlProps {
  /**
   * Fonction réagissant au click
   */
  onClick?: (level: number) => void;
  /**
   * L'interaction est en train de chargé ?
   */
  interactIsLoading?: boolean;
  /**
   * Debounce timeout
   */
  debounceTime?: number;
}

function MbjRangeCtrl({ className, ...props }: MbjRangeCtrlProps) {
  const [currentPoint, setCurrentPoint] = useState(props.currentPoint);
  const debouncedCurrentPoint: number = useDebounce<number>(currentPoint, props.debounceTime || 500);

  const [modified, setModified] = useState(false);

  const [hoverRefAddButton, isHoveredAddButton] = useHover<HTMLDivElement>();
  const [hoverRefSubtractButton, isHoveredSubtractButton] = useHover<HTMLDivElement>();

  useEffect(
    () => {
      console.log("ixi");
      if (debouncedCurrentPoint !== undefined && props.onClick && modified) {
        if (props.onClick) {
          props.onClick(currentPoint);
          console.log(currentPoint);
        }
      }
    },
    [debouncedCurrentPoint] // Only call effect if debounced search term changes
  );

  function changePoint(e: React.MouseEvent<HTMLButtonElement>, toAdd: number) {
    const Point = parseInt(currentPoint.toString()) + parseInt(toAdd.toString());
    if (Point >= 0 && Point <= props.nbPoints) {
      setModified(true);
      setCurrentPoint(Point);
    }
  }

  return (
    <div className={className}>
      <div ref={hoverRefSubtractButton}>
        <MbjIconButton
          onClick={(e) => changePoint(e, -1)}
          ariaLabel={"Diminuer le niveau de cette compétence"}
          icon={<RiSubtractFill />}
          size={"sm"}
          isPending={props.interactIsLoading}
          isRound
        />
      </div>
      <MbjRangeStatic
        {...props}
        currentPoint={currentPoint}
        pointDecrease={isHoveredSubtractButton}
        pointIncrease={isHoveredAddButton}
      />
      <div ref={hoverRefAddButton}>
        <MbjIconButton
          onClick={(e) => changePoint(e, 1)}
          ariaLabel={"Augmenter le niveau de cette compétence"}
          icon={<RiAddFill />}
          size={"sm"}
          isPending={props.interactIsLoading}
          isRound
        />
      </div>
    </div>
  );
}
/**
 * Composant layout card :
 */
export const MbjRange = styled(MbjRangeCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
}));
MbjRange.defaultProps = {
  themeColor: "primary",
};
