import React from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useGetConsult } from "@mbj-front-monorepo/data-access";
import { MbjMobiliteListItem } from "@mbj-front-monorepo/data-ui";
import { useParams } from "react-router-dom";

interface MobilitesSavedCardCtrlProps extends MbjCardClassicCtrlProps {}

const MobilitesSavedCardCtrl = (props: MobilitesSavedCardCtrlProps) => {
  const { id } = useParams();
  const mobilitesSavedQuery = useGetConsult(id ? parseInt(id) : undefined);

  return (
    <MbjCardClassic title="Dernières mobilités consultées" noPadding {...props}>
      {mobilitesSavedQuery.isLoading ? (
        <MbjLoading />
      ) : mobilitesSavedQuery.isError ? (
        "Errors"
      ) : mobilitesSavedQuery.data?.length === 0 ? (
        <p>Aucune mobilité consultée</p>
      ) : (
        <MbjScrollArea>
          {mobilitesSavedQuery.data.map(
            (mobiliteSaved, index: number) =>
              mobiliteSaved &&
              mobiliteSaved.ParticulierMobilite && (
                <MbjListItem key={mobiliteSaved.id} to={`${mobiliteSaved.ParticulierMobilite.id}`}>
                  <MbjMobiliteListItem mobilite={mobiliteSaved.ParticulierMobilite} />
                </MbjListItem>
              )
          )}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const MobilitesSavedCard = styled(MobilitesSavedCardCtrl)((props) => ({}));
