import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useRegisterParticulierOrly = () => {
  return useMutation(
    (data: Operation<"addOrlyUserCollection", "requestBody">) => {
      return fetchApi("/api/users/add_orly", { method: "post", json: data });
    },
    {
      onSuccess: (result) => {
        console.log(result);
      },
      onError: (error: any) => {
        console.log(error.message);
      },
    }
  );
};

export const useLogin = () => {
  const promise = (body: any) => fetchApi("/api/login", { method: "post", json: body });

  return useMutation<Awaited<ReturnType<typeof promise>>, Error>(
    (body: any) => {
      return promise(body);
    },
    {
      onSuccess: (result) => {
        localStorage.setItem("__user_token__", JSON.stringify(result.token).replace(/['"]+/g, ""));
      },
      onError: (error) => {
        console.log(error.message);
      },
    }
  );
};

export const useMe = () => {
  const promise = () => fetchApi("/api/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["me"], promise);
};
