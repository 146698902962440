import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetOffreInternePart = (ParticulierId: number | undefined) => {
  const promise = () => fetchApi("/api/offre_internes", { method: "get", query: { Particulier: ParticulierId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["offres_compa_part", { ParticulierId }], promise, {
    enabled: !!ParticulierId,
  });
};

export const useGetOffreInterneByMembre = (MemberId: number | undefined) => {
  const promise = () => fetchApi("/api/offre_internes", { method: "get", query: { Member: MemberId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["offres", { MemberId }], promise, {
    enabled: !!MemberId,
  });
};

export const useGetOffreInterne = (id: number | undefined) => {
  const promise = () => fetchApi("/api/offre_internes/{id}", { method: "get", params: { id: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["offre", { offreId: id }], promise, {
    enabled: !!id,
  });
};

export const useMutateOffreInterne = (offreID: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putOffreInterneItem", "requestBody">) =>
      fetchApi("/api/offre_internes/{id}", { method: "put", params: { id: offreID + "" }, json: data }),
    {
      onSuccess: (newOffre) => {
        queryClient.invalidateQueries(["offre"]);
        queryClient.setQueryData(["offre", { offreId: offreID }], newOffre);
        toast.success("Offre modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddOffreInterne = (memberId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postOffreInterneCollection", "requestBody">) =>
      fetchApi("/api/offre_internes", { method: "post", json: data }),
    {
      onSuccess: (newOffre) => {
        queryClient.invalidateQueries(["offres"]);
        queryClient.setQueryData(["offre", { offreId: newOffre.id }], newOffre);
        toast.success("Offre ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteOffreInterne = (offreInterneId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return fetchApi("/api/offre_internes/{id}", { method: "delete", params: { id: offreInterneId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["offres"]);
        toast.success("Offre interne supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetProxiEmployees = (offreID: number | undefined) => {
  const promise = () =>
    fetchApi("/api/employee_offre_internes", { method: "get", query: { OffreInterne: offreID + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employees_offre", { offreId: offreID }], promise, {
    enabled: !!offreID,
  });
};

export const useGetProxiParticulier = (offreID: number | undefined) => {
  const promise = () =>
    fetchApi("/api/particulier_offre_internes", { method: "get", query: { OffreInterne: offreID + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["particuliers_offre", { offreId: offreID }], promise, {
    enabled: !!offreID,
  });
};
