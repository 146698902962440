import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { BiUser } from "react-icons/bi";
import { MbjLinkBox, MbjLinkOverlay } from "@mbj-front-monorepo/ui";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface PersonneSuivieCardCtrlProps {
  className?: string;
  Portefeuille: GetElementType<Operation<"getPortefeuilleCollection">>;
}

const PersonneSuivieCardCtrl = ({ className, Portefeuille }: PersonneSuivieCardCtrlProps) => {
  return (
    <MbjLinkBox className={className}>
      <div className={`card-in`}>
        <div className={`head-card`}>
          <MbjLinkOverlay className={`name-place`} to={Portefeuille.Particulier?.id?.toString() || "0"}>
            {Portefeuille.Particulier?.prenom} {Portefeuille.Particulier?.nom}
          </MbjLinkOverlay>
          <div className={`roundAvatar`}>
            {Portefeuille.Particulier && Portefeuille.Particulier.avatarUrl ? (
              <img src={`${process.env.REACT_APP_API_URL_ROOT}${Portefeuille.Particulier.avatarUrl}`} />
            ) : (
              <BiUser />
            )}
          </div>
        </div>
        <div className={`body-card`}>
          <p>Ajouté le {Portefeuille.acceptAt ? new Date(Portefeuille.acceptAt).toLocaleDateString("fr-FR") : ""}</p>
        </div>
      </div>
    </MbjLinkBox>
  );
};

export const PersonneSuivieCard = styled(PersonneSuivieCardCtrl)((props) => ({
  flexBasis: "46%",
  margin: "0.5rem",
  "&:hover": {
    cursor: "pointer",
    ".card-in": {
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
    },
  },
  ".card-in": {
    background: "white",
    borderRadius: "4px",
    ".head-card": {
      display: "flex",
      justifyContent: "space-between",
      background: props.theme.primaryLighten,
      padding: "0.25rem",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      fontWeight: "bold",
      ".roundAvatar": {
        background: "white",
        borderRadius: "50%",
        overflow: "hidden",
        width: "30px",
        height: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        img: {
          width: "100%",
          height: "auto",
        },
        svg: {
          fontSize: "22px",
          margin: "auto",
        },
      },
    },
    ".body-card": {
      padding: "0.5rem",
      fontSize: "calc(14px + 0.2rem)",
    },
  },
}));
