import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjStaticInfo } from "@mbj-front-monorepo/ui";

interface ProfilProCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
  Member: Operation<"getMemberItem">;
}

const ProfilProCardCtrl = ({ className, Member, ...rest }: ProfilProCardCtrlProps) => {
  return (
    <MbjCardClassic title={"Informations"} className={className} {...rest}>
      <div className={`avatar-place`}>
        <div className={`round-avatar`}>
          <img src={`${process.env.REACT_APP_API_URL_ROOT}${Member.logoUrl}`} />
        </div>
      </div>
      <MbjStaticInfo label="Nom" info={Member.name} />
      <MbjStaticInfo label="Siret" info={Member.siret || "inconnu"} />
      <MbjStaticInfo label="Adresse" info={Member.adresse || "inconnu"} />
      <MbjStaticInfo info={`${Member.codePostal || "--"} ${Member.ville || ""}`} />
      <MbjStaticInfo label="E-mail" info={Member.email || "inconnu"} />
    </MbjCardClassic>
  );
};

export const ProfilProCard = styled(ProfilProCardCtrl)((props) => ({
  ".avatar-place": {
    width: "100%",
    marginBottom: "10px",
  },
  ".round-avatar": {
    width: "80px",
    height: "80px",
    position: "relative",
    margin: "auto",
    borderRadius: "50%",
    overflow: "hidden",
    img: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "auto",
    },
  },
}));
