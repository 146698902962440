import React from "react";
import styled from "styled-components";
import { useGetParticulier } from "@mbj-front-monorepo/data-access";
import { useLocation, useParams } from "react-router-dom";
import { MbjCardClassic, MbjFlex, MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { ProfilCard } from "./ProfilCard";
import { CompetencesCard } from "./CompetencesCard";

interface DashboardPartCtrlProps {
  className?: string;
}

const DashboardPartCtrl = ({ className }: DashboardPartCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const ParticulierQuery = useGetParticulier(id ? parseInt(id) : undefined);

  return (
    <div className={className}>
      {ParticulierQuery.isLoading ? (
        <MbjLoading />
      ) : ParticulierQuery.isError ? (
        "Error"
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: ParticulierQuery.data?.nom?.toUpperCase() + " " + ParticulierQuery.data?.prenom,
            }}
          />
          <MbjFlex>
            <ProfilCard to={"../informations"} particulierId={id || "-1"} basis={"30%"} />
            <MbjFlex direction={"column"} basis={"65%"}>
              <CompetencesCard to={"../competences"} particulierId={id || "-1"} />
              <MbjFlex>
                <MbjCardClassic title={"Métiers proches"} to={"../mobilites"} />
                <MbjCardClassic title={"CV"} to={"../cv"} />
              </MbjFlex>
            </MbjFlex>
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const DashboardPart = styled(DashboardPartCtrl)((props) => ({
  padding: "1% 2%",
}));
