import * as React from "react";
import {
  CustomSelect,
  MbjCardClassicCtrlProps,
  MbjHeading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { Operation, useGetMemberServices, useGetUserMetiers } from "@mbj-front-monorepo/data-access";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

interface MbjEmployeeEditEntrepriseInfosCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;

  employee: Operation<"getEmployeeItem">;

  memberId: number;
}

const MbjEmployeeEditEntrepriseInfosCtrl = (props: MbjEmployeeEditEntrepriseInfosCtrlProps) => {
  const UserMetiersQuery = useGetUserMetiers(props.memberId);
  const servicesQuery = useGetMemberServices();

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    control,
    reset,
  } = useFormContext();

  return (
    <>
      <MbjHeading className={"title"}>Informations pour l'entreprise</MbjHeading>
      <div className={"rowContainer"}>
        <MbjSimpleInputContainer errors={errors} label={"Métier exercé"} htmlFor={"UserMetier"}>
          <Controller
            name={"UserMetier"}
            control={control}
            rules={{ required: { value: true, message: "Ce champ est requis" } }}
            render={({ field }) => (
              <CustomSelect
                inputId={"UserMetier"}
                isLoading={UserMetiersQuery.isLoading}
                options={
                  UserMetiersQuery.data
                    ? UserMetiersQuery.data.map(function (um) {
                        return { id: um.id || 0, namePerso: um.namePerso || "" };
                      })
                    : undefined
                }
                getOptionLabel={(item) => item.namePerso || ""}
                getOptionValue={(item) => item.id + ""}
                isClearable={true}
                defaultValue={field.value}
                onChange={(selectedOption, triggeredAction) => {
                  if (selectedOption) {
                    field.onChange({ id: selectedOption.id, namePerso: selectedOption.namePerso });
                  }
                  if (triggeredAction.action === "clear") {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Service de l'entreprise"} htmlFor={"Service"}>
          <Controller
            name={"Service"}
            control={control}
            render={({ field }) => (
              <CustomSelect
                inputId={"Service"}
                isLoading={servicesQuery.isLoading}
                options={servicesQuery.data}
                getOptionLabel={(Service) => Service.libelle || ""}
                getOptionValue={(Service) => Service.id + ""}
                isClearable={true}
                defaultValue={field.value}
                onChange={(selectedOption, triggeredAction) => {
                  if (selectedOption) {
                    field.onChange(selectedOption);
                  }
                  if (triggeredAction.action === "clear") {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Salaire"}>
          <MbjSimpleInput
            name={`salary`}
            id={`salary`}
            type={"number"}
            noPadding
            register={register}
            registerOptions={{
              min: {
                value: 0,
                message: "Ne peut être négatif",
              },
              valueAsNumber: true,
            }}
          />
        </MbjSimpleInputContainer>
      </div>
    </>
  );
};
export const MbjEmployeeEditEntrepriseInfos = styled(MbjEmployeeEditEntrepriseInfosCtrl)((props) => ({}));
