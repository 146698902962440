import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { BiUser } from "react-icons/bi";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface MbjEntrepriseWaitCtrlProps {
  className?: string;
  Portefeuille: GetElementType<Operation<"getPortefeuilleProCollection">>;
}

const MbjEntrepriseWaitCtrl = ({ className, Portefeuille }: MbjEntrepriseWaitCtrlProps) => {
  return (
    <div className={`portefeuilleWait ${className}`}>
      <div className={`card-in`}>
        <div className={`head-card`}>
          <div className={`name-place`}>{Portefeuille.Member?.name}</div>
          <div className={`roundAvatar`}>
            <BiUser />
          </div>
        </div>
        <div className={`body-card`}>
          <p>
            Demandé le{" "}
            {Portefeuille.invitationAt ? new Date(Portefeuille.invitationAt).toLocaleDateString().slice(0, 10) : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export const EntrepriseWait = styled(MbjEntrepriseWaitCtrl)((props) => ({
  width: "50%",
  padding: "0.5rem",
  ".card-in": {
    background: "white",
    borderRadius: "4px",
    ".head-card": {
      background: props.theme.complementaryLighten,
      padding: "0.25rem",
      position: "relative",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      fontWeight: "bold",
      ".roundAvatar": {
        position: "absolute",
        background: "white",
        right: "10px",
        top: "100%",
        transform: "translateY(-50%)",
        borderRadius: "50%",
        overflow: "hidden",
        width: "30px",
        height: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        svg: {
          fontSize: "22px",
          margin: "auto",
        },
      },
    },
    ".body-card": {
      padding: "0.5rem",
      fontSize: "calc(14px + 0.2rem)",
    },
  },
}));
