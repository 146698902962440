import React, { useEffect } from "react";
import styled from "styled-components";
import { Operation, useGetTypesContracts, useMutateOffreInterne } from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import {
  CustomSelect,
  MbjButton,
  MbjFlex,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { getDateEN } from "@mbj-front-monorepo/utils";

interface EditionOffreCtrlProps {
  className?: string;
  OffreInterne: Operation<"getOffreInterneItem">;
}

const EditionOffreCtrl = ({ className, OffreInterne }: EditionOffreCtrlProps) => {
  const typeContractsQuery = useGetTypesContracts();
  const mutation = useMutateOffreInterne(OffreInterne.id || -1);
  const defaultValues = {
    libelle: OffreInterne.libelle,
    codePostal: OffreInterne.codePostal,
    description: OffreInterne.description ? OffreInterne.description : "",
    startAt: OffreInterne.startAt ? getDateEN(new Date(OffreInterne.startAt)) : "",
    endAt: OffreInterne.endAt ? getDateEN(new Date(OffreInterne.endAt)) : "",
    salaire: OffreInterne.salaire,
    TypeContrat: OffreInterne.TypeContrat ? OffreInterne.TypeContrat?.id?.toString() : "",
  };
  const methods = useForm<Operation<"putOffreInterneItem", "requestBody">>({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods.reset, OffreInterne]);
  return (
    <div className={`wrap-edition-offre-interne ${className}`}>
      <form
        onSubmit={methods.handleSubmit((data) => {
          mutation.mutate({
            ...data,
            TypeContrat: `/api/type_contrats/${data.TypeContrat}`,
            endAt: data.endAt === "" ? null : data.endAt,
          });
        })}
        className="Infos"
      >
        <MbjFlex direction={"column"} gap={"15px"} wrap={"nowrap"}>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Libelle de l'offre *"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              noPadding
              register={methods.register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjFlex>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de début*"}>
              <MbjSimpleInput
                name={"startAt"}
                id={"startAt"}
                type={"date"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "date début requise" } }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de fin"}>
              <MbjSimpleInput name={"endAt"} id={"endAt"} type={"date"} noPadding register={methods.register} />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjFlex>
            <MbjSimpleInputContainer
              errors={methods.formState.errors}
              label={"Type du contrat *"}
              htmlFor={"idContrat"}
            >
              <Controller
                name={"TypeContrat"}
                control={methods.control}
                rules={{ required: { value: true, message: "Contrat requis" } }}
                render={({ field }) => (
                  <CustomSelect
                    inputId={"TypeContrat"}
                    isLoading={typeContractsQuery.isLoading}
                    options={typeContractsQuery.data}
                    getOptionLabel={(option) => option?.libelle || ""}
                    getOptionValue={(option) => option?.id + ""}
                    isClearable={true}
                    defaultValue={OffreInterne.TypeContrat}
                    onChange={(selectedOption, triggeredAction) => {
                      if (selectedOption) {
                        field.onChange(selectedOption.id);
                      }
                      if (triggeredAction.action === "clear") {
                        field.onChange(null);
                      }
                    }}
                  />
                )}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjFlex>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Code Postal du poste"}>
              <MbjSimpleInput name={"codePostal"} id={"codePostal"} register={methods.register} />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Salaire du poste"}>
              <MbjSimpleInput
                name={"salaire"}
                id={"salaire"}
                type={"number"}
                register={methods.register}
                registerOptions={{ valueAsNumber: true, min: { value: 0, message: "Le salaire ne peut être négatif" } }}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjFlex>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Description du poste"}>
              <MbjSimpleTextArea
                name={`description`}
                id={`description`}
                noPadding
                register={methods.register}
                height={"90px"}
                resize={"none"}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjFlex alignSelf={"flex-end"}>
            {methods.formState.isDirty && (
              <MbjButton size={"sm"} type="button" onClick={() => methods.reset(defaultValues)} themeColor={"warning"}>
                Réinitialiser
              </MbjButton>
            )}
            <MbjButton size={"sm"} isPending={mutation.isLoading}>
              Confirmer
            </MbjButton>
          </MbjFlex>
        </MbjFlex>
      </form>
    </div>
  );
};

export const EditionOffre = styled(EditionOffreCtrl)((props) => ({
  padding: "1rem",
}));
