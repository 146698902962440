import React from "react";
import styled from "styled-components";
import { flexRender, Table } from "@tanstack/react-table";
import { MbjTableFilter } from "./parts/MbjTableFilter";
import { BaseColorsTheme } from "../../../assets/styles/ThemeType";

interface MbjTableV2CtrlProps {
  className?: string;

  themeColor?: BaseColorsTheme;
  table: Table<any>;

  withFooter?: boolean;
}

export const MbjTableV2Ctrl = (props: MbjTableV2CtrlProps) => {
  return (
    <table className={props.className}>
      <thead>
        {props.table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort() ? "clickable" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <MbjTableFilter column={header.column} table={props.table} />
                      ) : null}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {props.table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
      {props.withFooter && (
        <tfoot>
          {props.table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
};

export const MbjTableV2 = styled(MbjTableV2Ctrl)((props) => ({
  width: "100%",
  borderRadius: "0.4rem",
  borderCollapse: "separate",
  borderSpacing: 0,
  fontSize: "1.05rem",

  "thead, tfoot": {
    margin: 0,
    position: "sticky",
    top: 0,

    th: {
      backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Lighten`] : "none",
      borderBottom: "2px solid lightgray",
      borderRight: "2px solid lightgray",
      padding: "0.5rem 6px",
    },
  },

  //On ajoute les bordures arrondies en haut :
  thead: {
    "tr:first-child th:first-child": {
      borderTopLeftRadius: "0.4rem",
    },
    "tr:first-child th:last-child": {
      borderTopRightRadius: "0.4rem",
    },
  },

  tbody: {
    borderBottom: "1px solid lightgray",
    textAlign: "left",

    td: {
      padding: "0.4rem 4px",
    },

    "tr:nth-child(odd)": {
      backgroundColor: "#E7E9EB",
    },
    "tr:nth-child(even)": {
      backgroundColor: props.theme.background2,
    },
  },

  ".clickable": {
    paddingBottom: "0.2rem",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
}));

MbjTableV2.defaultProps = {
  themeColor: "primary",
};
