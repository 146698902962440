import React from "react";
import styled from "styled-components";
import { MbjAvatarImage } from "./parts/MbjAvatarImage";
import { GenericAvatarIcon } from "./parts/GenericAvatarIcon";
import { getAccessibleTextColor, randomColor } from "@mbj-front-monorepo/utils";
import { useImage } from "./parts/utils";

interface MbjAvatarCtrlProps {
  /**
   * The name of the person in the avatar.
   *
   * - if `src` has loaded, the name will be used as the `alt` attribute of the `img`
   * - If `src` is not loaded, the name will be used to create the initials
   */
  name?: string;
  /**
   * The image url of the `Avatar`
   */
  src?: string;

  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";

  icon?: React.ReactElement;

  iconLabel?: string;

  className?: string;
  /**
   * The background color of the avatar.
   */
  bg?: string;

  objectFit?: React.CSSProperties["objectFit"];

  withShadow?: boolean;

  padding?: string;
}
function MbjAvatarCtrl(props: MbjAvatarCtrlProps) {
  const status = useImage({ src: props.src });

  const notLoaded = status === "failed";
  //Si l'image n'est pas chargée, on génère une couleur aléatoire (afin que le texte ou l'icône de fallback soit visible)
  const bg = notLoaded ? randomColor() : props.bg;

  return (
    <span className={props.className} style={{ backgroundColor: bg, color: getAccessibleTextColor(bg || "white") }}>
      <MbjAvatarImage
        src={props.src}
        icon={props.icon || <GenericAvatarIcon />}
        name={props.name}
        iconLabel={props.iconLabel}
        objectFit={props.objectFit}
      />
    </span>
  );
}

/**
 * Composant contenant l'icône de l'utilisateur :
 */
export const MbjAvatar = styled(MbjAvatarCtrl)((props) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: props.withShadow ? "2px 2px 3px 1px #00000029" : "none",
  textTransform: "uppercase",
  fontWeight: "bold",
  borderRadius: "1000px",
  padding: props.padding,
  width:
    props.size === "xs"
      ? "1.5rem"
      : props.size === "sm"
      ? "2rem"
      : props.size === "md"
      ? "3rem"
      : props.size === "lg"
      ? "4rem"
      : props.size === "xl"
      ? "6rem"
      : props.size === "2xl"
      ? "8rem"
      : props.size === "full"
      ? "100%"
      : "none",

  height:
    props.size === "xs"
      ? "1.5rem"
      : props.size === "sm"
      ? "2rem"
      : props.size === "md"
      ? "3rem"
      : props.size === "lg"
      ? "4rem"
      : props.size === "xl"
      ? "6rem"
      : props.size === "2xl"
      ? "8rem"
      : props.size === "full"
      ? "100%"
      : "none",
  fontSize:
    props.size === "xs"
      ? "0.6rem"
      : props.size === "sm"
      ? "0.8rem"
      : props.size === "md"
      ? "1.2rem"
      : props.size === "lg"
      ? "1.6rem"
      : props.size === "xl"
      ? "2.4rem"
      : props.size === "2xl"
      ? "3.2rem"
      : props.size === "full"
      ? "40%"
      : "none",
}));
MbjAvatar.defaultProps = {
  size: "md",
  bg: randomColor(),
};
