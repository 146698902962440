import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetUserMetiers = (memberId?: number) => {
  const memberQuery = useGetMemberMe();
  const id = memberId ? memberId : memberQuery.data?.id;
  console.log('id>>'+id);
  const promise = () => fetchApi("/api/user_metiers", { method: "get", query: { Member: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["user_metiers", { id }], promise, {
    enabled: !!id,
  });
};

export const useGetUserMetier = (userMetierId?: number) => {
  const promise = () => fetchApi("/api/user_metiers/{id}", { method: "get", params: { id: userMetierId + "" } });
  return useQuery(["user_metier", { userMetierId }], promise, {
    enabled: !!userMetierId,
  });
};

export const useAddUserMetier = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postUserMetierCollection", "requestBody">) => {
      return fetchApi("/api/user_metiers", { method: "post", json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["user_metiers"]);
        queryClient.invalidateQueries(["user_metiers_statistiques"]);
        queryClient.setQueryData(["user_metier", { userMetierId: newData.id }], newData);
        toast.success("Métier ajouté !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateUserMetier = (useMetierId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putUserMetierItem", "requestBody">) => {
      return fetchApi("/api/user_metiers/{id}", { method: "put", params: { id: useMetierId + "" }, json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["user_metiers"]);
        queryClient.invalidateQueries(["user_metiers_statistiques"]);
        queryClient.setQueryData(["user_metier", { userMetierId: newData.id }], newData);
        toast.success("Fiche métier modifiée !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteUserMetier = (userMetierId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return fetchApi("/api/user_metiers/{id}", { method: "delete", params: { id: userMetierId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user_metiers"]);
        queryClient.invalidateQueries(["user_metiers_statistiques"]);
        toast.success("Métier supprimé", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetUserMetierStatistiques = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/user_metier_statistiques", { method: "get", query: { Member: memberId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["user_metiers_statistiques", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetActivitesUserMetiers = (userMetierId?: number) => {
  const promise = () =>
    fetchApi("/api/user_metier_activites", { method: "get", query: { UserMetier: userMetierId + "" } });
  return useQuery(["activites_user_metiers", { userMetierId }], promise, {
    enabled: !!userMetierId,
  });
};
export const useGetCompetencesUserMetiers = (userMetierId?: number) => {
  const promise = () =>
    fetchApi("/api/user_metier_competences", { method: "get", query: { UserMetier: userMetierId || -1 } });
  return useQuery(["competences_user_metiers", { userMetierId }], promise, {
    enabled: !!userMetierId,
  });
};

export const useUpdateNiveauActiviteUserMetiers = (userMetierId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { idUMActivite: number; niveau: number }) => {
      return fetchApi("/api/user_metier_activites/{id}", {
        method: "put",
        params: { id: data.idUMActivite + "" },
        json: { niveau: data.niveau },
      });
    },
    {
      onSuccess: (newUMActivite) => {
        queryClient.invalidateQueries(["activites_user_metiers", { userMetierId }]);
        toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddActiviteUserMetiers = (userMetierId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postUserMetierActiviteCollection", "requestBody">) => {
      return fetchApi("/api/user_metier_activites", { method: "post", json: data });
    },
    {
      onSuccess: (newEmplActi) => {
        queryClient.invalidateQueries(["activites_user_metiers", { userMetierId }]);
        queryClient.invalidateQueries(["competences_user_metiers", { userMetierId }]);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActiviteUserMetiers = (userMetierId?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idActivite: number) => {
      return fetchApi("/api/user_metier_activites/{id}", { method: "delete", params: { id: idActivite + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activites_user_metiers", { userMetierId }]);
        queryClient.invalidateQueries(["competences_user_metiers", { userMetierId }]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
