import React from "react";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {ProxiEmployee} from "./ProxiEmployee";
import {ProxiParticulier} from "./ProxiParticulier";

interface ProxiProfilsMainCtrlProps{
  className?:string;
}

const ProxiProfilsMainCtrl = ({className}:ProxiProfilsMainCtrlProps)=>{
  const {idOffre} = useParams();
  return (
    <div className={`ProxiProfilsMain ${className}`}>
      <ProxiEmployee idOffre={idOffre ? parseInt(idOffre) : undefined}/>
      <ProxiParticulier idOffre={idOffre ? parseInt(idOffre) : undefined}/>
    </div>
  )
}

export const ProxiProfilsMain = styled(ProxiProfilsMainCtrl)`
  padding: 0.5rem;
`
