import React, { useState } from "react";
import styled from "styled-components";
import { useGetMetiers, useGetParticulier } from "@mbj-front-monorepo/data-access";
import { useLocation, useParams } from "react-router-dom";
import {
  MbjCard,
  MbjCardBody,
  MbjCardHeader,
  MbjHeading,
  MbjListItem,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea,
  MbjSearchBar,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import { MobilitesByMbjCard } from "./MobilitesByMbjCard/MobilitesByMbjCard";
import { MobilitesSavedCard } from "./MobilitesSavedCard/MobilitesSavedCard";

interface MobilitesCtrlProps {
  className?: string;
}

const MobilitesCtrl = ({ className }: MobilitesCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const ParticulierQuery = useGetParticulier(id ? parseInt(id) : undefined);
  const metiersQuery = useGetMetiers();
  const [search, setSearch] = useState<string>("");

  const ListeMetiersFiltered = React.useMemo(() => {
    if (metiersQuery.isLoading || metiersQuery.isError || !metiersQuery.data) {
      return [];
    }
    return metiersQuery.data
      ?.filter((metier) => {
        if (search === "") {
          return metier;
        } else if (metier.libelle?.toLowerCase().includes(search.toLowerCase())) {
          return metier;
        }
        return undefined;
      })
      .sort((a, b) => {
        return (a.libelle || "0") > (b.libelle || "0") ? 1 : -1;
      });
  }, [metiersQuery.data, metiersQuery.isLoading, metiersQuery.isError, search]);
  return (
    <div className={className}>
      {ParticulierQuery.isLoading ? (
        <MbjLoading />
      ) : ParticulierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: ParticulierQuery.data?.nom?.toUpperCase() + " " + ParticulierQuery.data?.prenom,
              },
              { index: 3, label: "Mobilités" },
            ]}
          />
          <MbjWidgetClassic title={"Mobilité vers un métier précis"} bodyDirection={"column"}>
            <MbjSearchBar
              placeholder={"Rechercher un métier"}
              className={"SearchBar"}
              onChange={(event) => setSearch(event.target.value)}
            />
            <MbjCard className={"searchResult"}>
              <MbjCardHeader>
                <MbjHeading>Nombre de métier : {ListeMetiersFiltered.length}</MbjHeading>
              </MbjCardHeader>
              <MbjCardBody noPadding>
                <MbjScrollArea maxHeight={"150px"}>
                  {ListeMetiersFiltered.map((item, idx) => (
                    <MbjListItem key={item.id} to={location.pathname + "/" + item.id}>
                      {item.libelle}
                    </MbjListItem>
                  ))}
                </MbjScrollArea>
              </MbjCardBody>
            </MbjCard>
          </MbjWidgetClassic>
          <MbjWidgetClassic title={"Mobilité selon le profil"} bodyDirection={"row"} grow={1} bodyGrow={1}>
            <MobilitesByMbjCard
              flex={"1 1 50%"}
              lastUpdate={ParticulierQuery.data.lastMobiliteCalc ? ParticulierQuery.data.lastMobiliteCalc : ""}
            />
            <MobilitesSavedCard flex={"1 1 49%"} />
          </MbjWidgetClassic>
        </>
      )}
    </div>
  );
};

export const Mobilites = styled(MobilitesCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex !important",
  flexDirection: "column",

  ".searchResult": {
    ".MbjCardHeader": {
      borderBottom: `1px solid ${props.theme.primaryLighten}`,
    },
  },
}));
