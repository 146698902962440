import React, { useMemo } from "react";
import styled from "styled-components";
import { MbjHeading } from "@mbj-front-monorepo/ui";
import { useGetProxiParticulier } from "@mbj-front-monorepo/data-access";

interface ProxiParticulierCtrlProps {
  className?: string;
  idOffre: number | undefined;
}
const limit = 0;

const ProxiParticulierCtrl = ({ className, idOffre }: ProxiParticulierCtrlProps) => {
  const ParticulierOffreQuery = useGetProxiParticulier(idOffre);
  const listProxi = useMemo(() => {
    if (ParticulierOffreQuery.isLoading || ParticulierOffreQuery.isError || !ParticulierOffreQuery.data) {
      return [];
    } else {
      return ParticulierOffreQuery.data
        .sort((a, b) => {
          return (a.score || 0) > (b.score || 0) ? -1 : 1;
        })
        .filter((eo) => eo.score && eo.score >= limit);
    }
  }, [ParticulierOffreQuery.isLoading, ParticulierOffreQuery.isError, ParticulierOffreQuery.data]);
  return (
    <div className={`ProxiEmp ${className}`}>
      <MbjHeading>Proximite avec des particuliers</MbjHeading>
    </div>
  );
};

export const ProxiParticulier = styled(ProxiParticulierCtrl)``;
