import React, { useMemo } from "react";
import styled from "styled-components";
import { MbjHeading, MbjLoading } from "@mbj-front-monorepo/ui";
import { useGetProxiEmployees } from "@mbj-front-monorepo/data-access";
import { EmployeeProxiCard } from "./EmployeeProxiCard";

interface ProxiEmployeeCtrlProps {
  className?: string;
  idOffre: number | undefined;
}
const limit = 0.4;

const ProxiEmployeeCtrl = ({ className, idOffre }: ProxiEmployeeCtrlProps) => {
  const EmployeesOffreQuery = useGetProxiEmployees(idOffre);
  const listProxi = useMemo(() => {
    if (EmployeesOffreQuery.isLoading || EmployeesOffreQuery.isError || !EmployeesOffreQuery.data) {
      return [];
    } else {
      return EmployeesOffreQuery.data
        .sort((a, b) => {
          return (a.score || 0) > (b.score || 0) ? -1 : 1;
        })
        .filter((eo) => eo.score && eo.score >= limit);
    }
  }, [EmployeesOffreQuery.isLoading, EmployeesOffreQuery.isError, EmployeesOffreQuery.data]);
  return (
    <div className={`ProxiEmp ${className}`}>
      <MbjHeading>Proximite avec des employées</MbjHeading>
      <div className={`list-proxi`}>
        {EmployeesOffreQuery.isLoading ? (
          <MbjLoading />
        ) : EmployeesOffreQuery.isError ? (
          "Erreurs..."
        ) : (
          listProxi.map((item, idx) => <EmployeeProxiCard key={item.id} EmployeeProxi={item} />)
        )}
      </div>
    </div>
  );
};

export const ProxiEmployee = styled(ProxiEmployeeCtrl)`
  .list-proxi {
    margin-top: 10px;
    padding: 0.5rem;
  }
`;
