import React from "react";
import styled from "styled-components";
import { GenericAvatarIcon } from "./GenericAvatarIcon";
import { useImage } from "./utils";
import { MbjAvatarName } from "./MbjAvatarName";

interface MbjAvatarImageCtrlProps {
  src?: string;

  /**
   * The name of the person in the avatar.
   *
   * - if `src` has loaded, the name will be used as the `alt` attribute of the `img`
   * - If `src` is not loaded, the name will be used to create the initials
   */
  name?: string;

  icon: React.ReactElement;

  iconLabel?: string;

  className?: string;

  objectFit?: React.CSSProperties["objectFit"];
}
function MbjAvatarImageCtrl(props: MbjAvatarImageCtrlProps) {
  const status = useImage({ src: props.src });

  const hasLoaded = status === "loaded";

  const showFallback = !props.src || !hasLoaded;

  if (showFallback) {
    return props.name ? (
      <MbjAvatarName name={props.name} className={"name " + props.className} />
    ) : (
      React.cloneElement(props.icon, {
        className: props.className,
        role: "img",
        "aria-label": props.iconLabel,
      })
    );
  }

  return <img src={props.src} alt={props.name} className={props.className} />;
}

/**
 * Composant contenant l'icône de l'utilisateur :
 */
export const MbjAvatarImage = styled(MbjAvatarImageCtrl)((props) => ({
  "&:not(.name)": {
    width: "100%",
    height: "100%",
    objectFit: props.objectFit,
  },
  borderRadius: "1000px",
}));
MbjAvatarImage.defaultProps = {
  icon: <GenericAvatarIcon />,
  objectFit: "cover",
};
