import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { InternDesktopLayout } from "@mbj-front-monorepo/ui";
import { MesPersonnesSuivies } from "./MesPersonnesSuivies/MesPersonnesSuivies";
import { NotFound } from "../NotFound/NotFound";
import { PersonneSuivieDetails } from "./PersonneSuivieDetails/PersonneSuivieDetails";

export const PersonnesSuivies = () => {
  return (
    <div className="Collaborateurs">
      <Routes>
        <Route element={<InternDesktopLayout type="Conseiller" />}>
          <Route path="" element={<MesPersonnesSuivies />} />
        </Route>
        <Route path="/:id/*" element={<PersonneSuivieDetails />} />s
        <Route path="*" element={<NotFound infos={"ici"} />} />
      </Routes>
    </div>
  );
};
