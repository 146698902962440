import React from "react";
import styled from "styled-components";
import { Operation, useGetEmployee } from "@mbj-front-monorepo/data-access";
import { GrMapLocation } from "react-icons/gr";
import { FaSuitcase } from "react-icons/fa";
import { MbjLoading } from "@mbj-front-monorepo/ui";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface EmployeeProxiCardCtrlProps {
  className?: string;
  EmployeeProxi: GetElementType<Operation<"getEmployeeOffreInterneCollection">>;
}

const EmployeeProxiCardCtrl = ({ className, EmployeeProxi }: EmployeeProxiCardCtrlProps) => {
  const EmployeeQuery = useGetEmployee(EmployeeProxi.Employee?.id);
  return (
    <div className={`EmployeeProx ${className}`}>
      <div className={`name-score`}>
        <div className={"name"}>
          {EmployeeProxi.Employee?.prenom} {EmployeeProxi.Employee?.nom}
        </div>
        <div className={"score"}>{EmployeeProxi.score ? `${Math.round(EmployeeProxi.score * 10000) / 100}%` : ""}</div>
      </div>
      <div className={`informations`}>
        {EmployeeQuery.isLoading ? (
          <MbjLoading />
        ) : EmployeeQuery.isError ? (
          "Erreurs???"
        ) : (
          <>
            <div className={`one-infos`}>
              <div className={`icon-place`}>
                <FaSuitcase />
              </div>
              <div className={"info"}>
                {EmployeeQuery.data.UserMetier ? EmployeeQuery.data.UserMetier?.namePerso : "inconnu"}
              </div>
            </div>
            <div className={`one-infos`}>
              <div className={`icon-place`}>
                <GrMapLocation />
              </div>
              <div className={"info"}>
                {EmployeeProxi.Employee?.adresse ? EmployeeProxi.Employee.adresse : "inconnue"}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const EmployeeProxiCard = styled(EmployeeProxiCardCtrl)((props) => ({
  background: "white",
  fontSize: "16px",
  padding: "0.5rem",
  borderRadius: "4px",
  marginBottom: "10px",
  width: "100%",
  "&:hover": {
    filter: "brightness(95%)",
    cursor: "pointer",
  },
  ".name-score": {
    display: "flex",
    justifyContent: "space-between",
    color: props.theme.primaryLighten,
    fontWeight: "bold",
  },
  ".informations": {
    paddingLeft: "10px",
    margin: "10px auto",
    ".one-infos": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: "10px",
      alignItems: "center",
    },
  },
}));
