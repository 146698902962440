import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface NotFoundProps {
  infos?:string;
}

function NotFoundCtrl(props: NotFoundProps) {
  const location = useLocation();
  return <p>Page not found for {location.pathname} ! {props.infos ? props.infos : ''}</p>;
}
export const NotFound = styled(NotFoundCtrl)((props) => ({}));
