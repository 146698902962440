import styled from "styled-components";
import * as React from "react";
import { MbjAccordionHeader } from "./MbjAccordionHeader";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { MbjAccordionChevron } from "./MbjAccordionChevron";
import { BaseColorsTheme } from "../../../../assets/styles/ThemeType";

interface MbjAccordionTriggerCtrlProps {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'élément sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   *
   */
  children?: React.ReactNode;

  className?: string;
}
const MbjAccordionTriggerCtrl = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentProps<typeof AccordionPrimitive.Trigger>
>((props: MbjAccordionTriggerCtrlProps, forwardedRef) => {
  return (
    <MbjAccordionHeader>
      <AccordionPrimitive.Trigger className={props.className} ref={forwardedRef}>
        <MbjAccordionChevron aria-hidden />
        {props.children}
      </AccordionPrimitive.Trigger>
    </MbjAccordionHeader>
  );
});
export const MbjAccordionTrigger = styled(MbjAccordionTriggerCtrl)<MbjAccordionTriggerCtrlProps>((props) => ({
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  cursor: "pointer",
  padding: "0.6rem 20px",
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontSize: "calc(10px + 0.5vmin)",
  gap: "20px",
  lineHeight: 1,
  color: props.themeColor !== "grey" ? props.theme.background2 : "black",
  boxShadow: `0 1px 0 ${props.theme.dark}`,

  '&[data-state="closed"]': {
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Pastel`] : "none",
  },
  '&[data-state="open"]': {
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Pastel`] : "none",
  },
  "&:hover": {
    backgroundColor: props.themeColor ? props.theme[props.themeColor] : "none",
  },
  "&:focus-visible": {
    border: "solid 2px red",
  },
}));
MbjAccordionTrigger.defaultProps = {
  themeColor: "grey",
};
