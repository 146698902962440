import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useGetEmployee, useGetMember } from "@mbj-front-monorepo/data-access";
import { MbjFlex, MbjLoading, MbjNavPath, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { MbjEmployeeActiviteAccordion, MbjEmployeeInformationsCard } from "@mbj-front-monorepo/data-ui";

interface CollaborateurDetailCtrlProps {
  className?: string;
}

const CollaborateurDetailCtrl = (props: CollaborateurDetailCtrlProps) => {
  const location = useLocation();
  const { idCollab } = useParams();
  const { id } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  const EmployeeQuery = useGetEmployee(idCollab ? parseInt(idCollab) : undefined);
  return (
    <div className={props.className}>
      {MemberQuery.isLoading || EmployeeQuery.isLoading ? (
        <MbjLoading />
      ) : MemberQuery.isLoading || EmployeeQuery.isLoading ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: MemberQuery.data?.name,
              },
              { index: 3, label: "Collaborateurs" },
              { index: 4, label: EmployeeQuery.data?.prenom + " " + EmployeeQuery.data?.nom },
            ]}
          />
          <MbjFlex>
            <MbjEmployeeInformationsCard title="Informations générales" employeeId={id || "-1"} flex={"1 1 25%"} />
            <MbjWidgetClassic title={"Compétences"} basis={"74%"} bodyGrow={1}>
              {idCollab && (
                <MbjEmployeeActiviteAccordion employeeId={parseInt(idCollab)} callToAction={false} isEditable={false} />
              )}
            </MbjWidgetClassic>
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const CollaborateurDetail = styled(CollaborateurDetailCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex !important",
  flexDirection: "column",

  ".infos-content": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  ".line-info": {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
  },
}));
