import * as React from "react";
import styled from "styled-components";
import { MbjHeading } from "../../atoms/MbjHeading/MbjHeading";
import { MbjCard, MbjCardCtrlProps } from "./MbjCard";
import { MbjCardHeader } from "./MbjCardParts/MbjCardHeader";
import { MbjCardBody } from "./MbjCardParts/MbjCardBody";
import { MbjCardFooter } from "./MbjCardParts/MbjCardFooter";
import { Link, To } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import { MbjAspectRatio } from "../../molecules/MbjAspectRatio/MbjAspectRatio";

export interface MbjCardClassicCtrlProps extends MbjCardCtrlProps {
  /**
   * Classe HTML de la card?
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de la card sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "dark";
  /**
   * Titre de la card ?
   */
  title?: string;
  /**
   * Contenu de la card ?
   */
  children?: React.ReactNode;
  /**
   * Padding ?
   */
  noPadding?: boolean;
  /**
   * Valeur d'overflow ?
   */
  overflow?: React.CSSProperties["overflow"];

  /**
   * Footer de la carte ?
   */
  footer?: string | React.ReactNode;
  /**
   * Couleur
   */
  colorBack?: string;

  to?: To;
}

function MbjCardClassicCtrl(props: MbjCardClassicCtrlProps) {
  return (
    <MbjCard className={props.className} {...props}>
      {props.title && (
        <MbjCardHeader>
          <MbjHeading themeColor={props.themeColor}>{props.title}</MbjHeading>
          {props.to && (
            <Link to={props.to} className={"redirectLink"}>
              <MbjAspectRatio ratio={1}>
                <BiLinkExternal />
              </MbjAspectRatio>
            </Link>
          )}
        </MbjCardHeader>
      )}
      {props.children && <MbjCardBody noPadding={props.noPadding}>{props.children}</MbjCardBody>}
      {props.footer && <MbjCardFooter>{props.footer}</MbjCardFooter>}
    </MbjCard>
  );
}
/**
 * Composant layout card :
 */
export const MbjCardClassic = styled(MbjCardClassicCtrl)<MbjCardClassicCtrlProps>((props) => ({
  "& > .MbjCardHeader": {
    backgroundColor: props.colorBack ? props.colorBack : props.theme.primaryLighten,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  overflow: props.overflow,

  "& .redirectLink": {
    height: "100%",
    aspectRatio: "1/1",
    maxHeight: "25px",
  },
}));
MbjCardClassic.defaultProps = {
  themeColor: "primary",
  overflow: "visible",
  grow: 1,
  noPadding: false,
};
