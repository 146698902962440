import React from "react";
import styled from "styled-components";
import { Operation, useAcceptJoin } from "@mbj-front-monorepo/data-access";
import {
  MbjButton,
  MbjCard,
  MbjCardBody,
  MbjCardHeader,
  MbjEmailSimpleInput,
  MbjFlex,
  MbjHeading,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";

interface FormAcceptJoinCtrlProps {
  className?: string;
  Portefeuille: Operation<"joinWalletPortefeuilleCollection">;
  ActionEnded: () => void;
}

const FromAcceptJoinCtrl = ({ className, Portefeuille, ActionEnded }: FormAcceptJoinCtrlProps) => {
  const mutationPortefeuille = useAcceptJoin(Portefeuille.id || -1);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <MbjCard className={className}>
      <MbjCardHeader>
        <MbjHeading>Adhésion au portefeuille</MbjHeading>
      </MbjCardHeader>
      <MbjCardBody>
        <MbjFlex direction={"column"} gap={"15px"}>
          <p>
            Bonjour <strong>{Portefeuille.Particulier?.prenom}</strong>, afin de terminer votre adhésion au portefeuille
            de votre conseiller
            <br />
            Merci de renseigner votre adresse email
          </p>

          <form
            onSubmit={handleSubmit((data) => {
              const Today = new Date();
              mutationPortefeuille.mutate(
                { acceptAt: Today.toISOString() },
                {
                  onSuccess: () => {
                    ActionEnded();
                  },
                }
              );
            })}
          >
            <MbjFlex direction={"column"} gap={"15px"}>
              <MbjSimpleInputContainer errors={errors} label={"Email"}>
                <MbjEmailSimpleInput
                  name={"email"}
                  id={"email"}
                  noPadding
                  register={register}
                  registerOptions={{
                    required: {
                      value: true,
                      message: "Email requis",
                    },
                    validate: (value) => {
                      if (value !== Portefeuille.Particulier?.email) {
                        return "Votre email ne semble pas correspondre à nos données";
                      }
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjButton isPending={mutationPortefeuille.isLoading} alignSelf={"flex-end"}>
                Confirmer
              </MbjButton>
            </MbjFlex>
          </form>
        </MbjFlex>
      </MbjCardBody>
    </MbjCard>
  );
};

export const FormAcceptJoin = styled(FromAcceptJoinCtrl)((props) => ({
  width: "50%",
}));
