import { useGetConseillerMe } from "../conseiller";
import { fetchApi } from "../../services/fetchApi";
import { useQuery } from "@tanstack/react-query";

export const useGetPortefeuillePro = () => {
  const ConseillerQuery = useGetConseillerMe();
  const idConseiller = ConseillerQuery.data?.id;
  const promise = () => fetchApi(`/api/portefeuille_pros`, { method: "get", query: { Conseiller: idConseiller + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["portefeuilles_pro", idConseiller], promise, {
    enabled: !!idConseiller,
  });
};
