import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useGetMember, useGetOffreInterneByMembre } from "@mbj-front-monorepo/data-access";
import { MbjButton, MbjLoading, MbjNavPath, MbjSearchBar, MbjWidgetClassic, useModal } from "@mbj-front-monorepo/ui";
import { OffreCard } from "./OffreCard";
import { NouvelleOffreModal } from "./NouvelleOffreModal";

interface OffresProCtrlProps {
  className?: string;
}

const OffresProCtrl = ({ className }: OffresProCtrlProps) => {
  const [search, setSearch] = useState<string>("");
  const location = useLocation();
  const { id } = useParams();
  const MemberQuery = useGetMember(id ? parseInt(id) : undefined);
  const OffresQuery = useGetOffreInterneByMembre(id ? parseInt(id) : undefined);
  const { isShowing, toggle } = useModal();

  const ListOffreFiltered = useMemo(() => {
    if (OffresQuery.isLoading || OffresQuery.isError || !OffresQuery.data) {
      return [];
    } else {
      if (search === "") {
        return OffresQuery.data;
      } else {
        const regex = new RegExp("" + search + "", "gi");
        return OffresQuery.data.filter((o) => {
          if (o.libelle?.match(regex)) return true;
          if (o.UserMetier && o.UserMetier.Metier) {
            if (o.UserMetier.namePerso?.match(regex) || o.UserMetier?.Metier?.libelle?.match(regex)) {
              return true;
            }
          }
          return false;
        });
      }
    }
  }, [OffresQuery.isLoading, OffresQuery.isError, OffresQuery.data, search]);

  return (
    <div className={`Dash ${className}`}>
      {MemberQuery.isLoading ? (
        <MbjLoading />
      ) : MemberQuery.isLoading ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: MemberQuery.data?.name,
              },
              { index: 3, label: "Offres" },
            ]}
          />
          <MbjWidgetClassic title={"Offres"} bodyDirection={"column"} grow={1} bodyGrow={1}>
            <MbjButton className={"addOffre"} onClick={toggle}>
              Nouvelle offre
            </MbjButton>
            <MbjSearchBar placeholder="Rechercher une offre" onChange={(event) => setSearch(event.target.value)} />
            {OffresQuery.isLoading ? (
              <MbjLoading />
            ) : OffresQuery.isError ? (
              "Erreurs"
            ) : (
              ListOffreFiltered.map((item, idx) => <OffreCard OffreInterne={item} key={item.id} />)
            )}
          </MbjWidgetClassic>
          <NouvelleOffreModal isShowing={isShowing} hide={toggle} memberId={parseInt(id || "-1")} />
        </>
      )}
    </div>
  );
};

export const OffresPro = styled(OffresProCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex !important",
  flexDirection: "column",

  ".addOffre": {
    alignSelf: "flex-end",
  },
}));
