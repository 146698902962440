import React from "react";
import styled from "styled-components";

interface MbjOptionCtrlProps {
  /**
   * Classe HTML de l'élément
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'élément sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Texte de l'élément :
   */
  text?: string;
  children?: React.ReactNode;
  /**
   * Fonction déclenchée au click ?
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function MbjOptionCtrl(props: MbjOptionCtrlProps) {
  return (
    <div className={props.className} onClick={props.onClick}>
      <span className={"text-option"}>{props.text || props.children}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.08 272.08">
        <path
          d="m169.98 136.04 95-95a24.02 24.02 0 0 0-33.94-34l-95 95-95-95a24.04 24.04 0 0 0-34 34l95 95-95 95a24.04 24.04 0 1 0 34 34l95-95 95 95a24.04 24.04 0 0 0 34-34z"
          stroke="none"
        />
      </svg>
    </div>
  );
}

/**
 * Options pour une recherche ou autre
 */
export const MbjOption = styled(MbjOptionCtrl)((props) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "calc(7.5px + 0.8vmin)",
  borderRadius: "1em",
  padding: "0.15em 0.5em",
  width: "fit-content",
  fontWeight: 600,
  cursor: props.onClick ? "pointer" : "default",
  color: props.theme.dark,
  transition: "transform ease-in 0.05s",

  ".text-option": {
    wordBreak: "break-all",
  },
  svg: {
    fill: props.theme.dark,
    height: "8px",
    aspectRatio: "1 / 1",
    marginTop: "0.2em",
    marginLeft: "0.5em",
  },

  backgroundColor:
    props.themeColor === "primary"
      ? props.theme.primaryPastel
      : props.themeColor === "secondary"
      ? props.theme.secondaryPastel
      : props.themeColor === "third"
      ? props.theme.thirdPastel
      : props.themeColor === "complementary"
      ? props.theme.complementaryPastel
      : "none",
  "&:hover, &:focus": {
    backgroundColor:
      props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : "none",
  },
  "&:active": {
    transform: "scale(0.96)",
    backgroundColor:
      props.themeColor === "primary"
        ? props.theme.primaryLighten
        : props.themeColor === "secondary"
        ? props.theme.secondaryLighten
        : props.themeColor === "third"
        ? props.theme.thirdLighten
        : props.themeColor === "complementary"
        ? props.theme.complementaryLighten
        : "none",
  },
}));
MbjOption.defaultProps = {
  themeColor: "third",
};
