import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface MbjDebouncedInputCtrlProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: string | number;

  onChange: (value: string | number) => void;

  debounce?: number;
}

export const MbjDebouncedInputCtrl = (props: MbjDebouncedInputCtrlProps) => {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      props.onChange(value);
    }, props.debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
};

export const MbjDebouncedInput = styled(MbjDebouncedInputCtrl)((props) => ({
  border: "none",
  borderRadius: "0.35rem",
  backgroundColor: props.theme.background1,
  padding: "0.3rem 0.1rem",
  fontSize: "1rem",
}));

MbjDebouncedInput.defaultProps = {
  debounce: 500,
};
