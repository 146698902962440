import React, { useEffect } from "react";
import styled from "styled-components";
import { Operation, useGetConseillerMe, useMutateConseiller } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import {
  MbjButton,
  MbjCardClassic,
  MbjLoading,
  MbjNavPath,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";

interface ProfilConseillerCtrlProps {
  className?: string;
}

const ProfilConseillerCtrl = ({ className }: ProfilConseillerCtrlProps) => {
  const location = useLocation();
  const conseillerMeQuery = useGetConseillerMe();
  const mutateConseiller = useMutateConseiller();
  const defaultValues = {
    nom: conseillerMeQuery.data?.nom,
    prenom: conseillerMeQuery.data?.prenom,
    email: conseillerMeQuery.data?.email,
    adresse: conseillerMeQuery.data?.adresse,
    codepostal: conseillerMeQuery.data?.codepostal,
    ville: conseillerMeQuery.data?.ville,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
  } = useForm<Operation<"putConseillerItem", "requestBody">>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, conseillerMeQuery.data]);

  return (
    <div className={`ProfilConseiller ${className}`}>
      {conseillerMeQuery.isLoading ? (
        <MbjLoading />
      ) : conseillerMeQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: "Votre profil",
            }}
          />
          <MbjCardClassic title={"Mes informations"} grow={0}>
            <form
              onSubmit={handleSubmit((data) => {
                mutateConseiller.mutate(data);
              })}
              className="Infos"
            >
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Nom *"}>
                  <MbjSimpleInput
                    name={"nom"}
                    id={"nom"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Nom requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Prénom *"}>
                  <MbjSimpleInput
                    name={"prenom"}
                    id={"prenom"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Prénom requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Email *"}>
                  <MbjSimpleInput
                    name={"email"}
                    id={"email"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Email requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Adresse"}>
                  <MbjSimpleInput name={"adresse"} id={"adresse"} noPadding register={register} />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Code postal"}>
                  <MbjSimpleInput name={"codepostal"} id={"codepostal"} noPadding register={register} />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Ville"}>
                  <MbjSimpleInput name={"ville"} id={"ville"} noPadding register={register} />
                </MbjSimpleInputContainer>
              </div>
              <div className={"interactContainer"}>
                {isDirty && (
                  <MbjButton size={"sm"} type="button" onClick={() => reset(defaultValues)} themeColor={"warning"}>
                    Réinitialiser
                  </MbjButton>
                )}
                <MbjButton size={"sm"} isPending={mutateConseiller.isLoading}>
                  Confirmer
                </MbjButton>
              </div>
            </form>
          </MbjCardClassic>
        </>
      )}
    </div>
  );
};

export const ProfilConseiller = styled(ProfilConseillerCtrl)`
  padding: 1% 2%;
  .rowContainer {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .interactContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-self: end;
  }
`;
