import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MbjMenuLinks } from "../MbjMenuLinks";
import { MbjBack } from "../../../atoms/MbjBack/MbjBack";
import { MbjLink } from "../../../atoms/MbjLink/MbjLink";
import { MbjItemMenuDesktop } from "../../../atoms/MbjItemMenu/desktop/MbjItemMenuDesktop";

interface MbjMenuDesktopCtrlProps {
  /**
   * Classe HTML du Menu
   */
  className?: string;
  /**
   * Liens du menu :
   */
  menuLinks: MbjMenuLinks[];

  /**
   * Couleur utilisée pour représenter l'ordre d'importance du Menu sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Le Menu doit-il être replié ?
   */
  isFold?: boolean;
  /**
   * Hauteur du menu
   */
  height?: string;
  /**
   * Largeur du menu
   */
  width?: string;
  /**
   * fonction à appeler lors de la déconnexion :
   */
  disonnectionCallback?: () => void;
}

function MbjMenuDesktopCtrl(props: MbjMenuDesktopCtrlProps) {
  const [isFold, setIsFold] = useState(props.isFold);
  const navigate = useNavigate();

  function foldMenu() {
    setIsFold((prevCheck) => !prevCheck);
  }
  function handleDeconnexion(e: any) {
    console.log("deconnect");
    //navigate("/login");
    if (props.disonnectionCallback) {
      props.disonnectionCallback();
      navigate("/login");
    }
  }

  return (
    <div className={props.className + " " + isFold}>
      <nav className="NavContainer">
        {props.menuLinks.map((link, index) => (
          <MbjItemMenuDesktop label={link.label} to={link.to} isFold={isFold} themeColor={props.themeColor} key={index}>
            {link.icon}
          </MbjItemMenuDesktop>
        ))}
      </nav>
      <div className="bottom">
        <div className="left">
          <MbjLink themeColor="white" isNavLink to="mentions">
            Mentions légales
          </MbjLink>
          <MbjLink themeColor="white" onClick={handleDeconnexion}>
            Déconnexion
          </MbjLink>
        </div>
        <MbjBack
          className="foldMenuButton"
          basicColor="white"
          height="25px"
          themeColor={props.themeColor}
          onClick={foldMenu}
        />
      </div>
    </div>
  );
}

export const MbjMenuDesktop = styled(MbjMenuDesktopCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  height: props.height,
  width: props.width,
  overflowX: "hidden",
  overflowY: "auto",
  backgroundColor: props.theme.dark,
  minWidth: "fit-content",
  boxShadow: "6px 0px 8px -2px #0000004f",
  justifyContent: "space-between",

  transitionDuration: "0.30s",
  transitionProperty: "all",
  transitionTimingFunction: "linear",

  svg: {
    height: "20px",
    width: "20px",
  },

  "& .bottom": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px 45px 15px",

    "& .left": {
      height: "40px",
      opacity: "1",
      visibility: "visible",
      width: "fit-content",
      transition: "visibility 0s linear 150ms, opacity 300ms linear, width 0.3s linear",

      display: "unset",
      fontSize: "1.4rem",
    },
    "& .foldMenuButton": {
      transform: "rotate( 0deg )",
      transition: "transform 0.3s linear",
    },
  },

  //lorsque replié :
  "&.true": {
    width: "0px",
    transition: "width 0.3s linear",

    "& .bottom .left": {
      opacity: "0",
      visibility: "hidden",
      width: "0px",
      transition: "visibility 0s linear 300ms, opacity 300ms linear, width 0.3s linear",
    },
    "& .bottom .foldMenuButton": {
      transform: "rotate( 180deg )",
      transition: "transform 0.3s linear",
    },
  },
}));
MbjMenuDesktop.defaultProps = {
  themeColor: "secondary",
  isFold: false,
  height: "100%",
  width: "18vw",
};
