import React from "react";
import styled from "styled-components";
import { useGetConseillerMe } from "@mbj-front-monorepo/data-access";
import { MbjAvatar, MbjNavPath } from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";

interface DashboardCtrlProps {
  className?: string;
}

const DashboardCtrl = ({ className }: DashboardCtrlProps) => {
  const location = useLocation();
  const ConseillerQuery = useGetConseillerMe();
  return (
    <div className={`DashConseiller ${className}`}>
      <MbjNavPath routePath={location.pathname} />
      <MbjAvatar src={"https://bit.ly/dan-abramo qfdsfv"} size={"md"} name={"dan abrahmov"} />
    </div>
  );
};

export const Dashboard = styled(DashboardCtrl)((props) => ({
  padding: "1% 2%",
  overflowY: "auto",

  ".test": {
    backgroundColor: "red",
  },
}));
