import React from "react";
import styled from "styled-components";
import { useGetParticulier } from "@mbj-front-monorepo/data-access";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjFlex, MbjLoading, MbjStaticInfo } from "@mbj-front-monorepo/ui";
import { getDateFR } from "@mbj-front-monorepo/utils";

interface ProfilCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;

  particulierId: string;
}

const ProfilCardCtrl = (props: ProfilCardCtrlProps) => {
  const ParticulierQuery = useGetParticulier(parseInt(props.particulierId));

  return ParticulierQuery.isLoading ? (
    <MbjLoading />
  ) : ParticulierQuery.isError ? (
    <p>Erreur ...</p>
  ) : (
    <MbjCardClassic title={"Informations"} className={props.className} {...props}>
      <div className={`avatar-place`}>
        <div className={`round-avatar`}>
          <img src={`${process.env.REACT_APP_API_URL_ROOT}${ParticulierQuery.data.avatarUrl}`} />
        </div>
      </div>
      <MbjFlex direction={"column"}>
        <MbjStaticInfo label="Prénom et Nom" info={`${ParticulierQuery.data.prenom} ${ParticulierQuery.data.nom}`} />
        <MbjStaticInfo
          label="Date de naissance"
          info={
            ParticulierQuery.data.dateNaissance ? getDateFR(new Date(ParticulierQuery.data.dateNaissance)) : "inconnue"
          }
        />
        <MbjStaticInfo
          label="adresse"
          info={ParticulierQuery.data.adresse ? ParticulierQuery.data.adresse : "inconnue"}
        />
        <MbjStaticInfo
          info={`${ParticulierQuery.data.codepostal ? ParticulierQuery.data.codepostal : "--"} ${
            ParticulierQuery.data.ville ? ParticulierQuery.data.ville : ""
          }`}
        />
      </MbjFlex>
    </MbjCardClassic>
  );
};

export const ProfilCard = styled(ProfilCardCtrl)`
  .avatar-place {
    width: 100%;
    margin-bottom: 10px;
  }
  .round-avatar {
    width: 80px;
    height: 80px;
    position: relative;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
    }
  }
`;
