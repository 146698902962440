import React from "react";
import styled from "styled-components";
import { useGetParticulierCompetences } from "@mbj-front-monorepo/data-access";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjListItem,
  MbjLoading,
  MbjRangeStatic,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";

interface CompetencesCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
  particulierId: string;
}

const CompetencesCardCtrl = (props: CompetencesCardCtrlProps) => {
  const competencesQuery = useGetParticulierCompetences(parseInt(props.particulierId));
  return (
    <MbjCardClassic title={"Compétences"} className={props.className} noPadding {...props}>
      {competencesQuery.isLoading ? (
        <MbjLoading />
      ) : competencesQuery.isError ? (
        <p>Erreur...</p>
      ) : (
        <MbjScrollArea maxHeight={"250px"}>
          {competencesQuery.data?.map((competencePart, idx) => (
            <MbjListItem key={competencePart?.Competence?.id} className={"lineCompet"}>
              <div className="libelle">{competencePart.Competence?.libelle}</div>
              <MbjRangeStatic nbPoints={5} currentPoint={competencePart.Niveau || 0} themeColor={"third"} />
            </MbjListItem>
          ))}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const CompetencesCard = styled(CompetencesCardCtrl)((props) => ({
  ".lineCompet": {
    fontSize: "15px",
    justifyContent: "space-between",
    gap: "5px",
  },
}));
