import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logoMbj from "../../../assets/images/logo-mbj.png";
import { useGetIfJoinPortefeuille } from "@mbj-front-monorepo/data-access";
import { MbjLoading } from "@mbj-front-monorepo/ui";
import { FormAcceptJoin } from "./FormAcceptJoin";

interface AcceptJoinCtrlProps {
  className?: string;
}

const AcceptJoinCtrl = ({ className }: AcceptJoinCtrlProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const TabParams = [...Array.from(searchParams)];
  const [actionEnd, setActionEnd] = useState<boolean>(false);
  const [myCode, setMyCode] = useState<string | undefined>(undefined);
  const PortefeuilleQuery = useGetIfJoinPortefeuille(myCode);
  useEffect(() => {
    const TabParams = [...Array.from(searchParams)];
    const TabCode = TabParams.find((tab) => tab[0] === "code");
    if (TabCode) {
      setMyCode(TabCode[1]);
    }
  }, [searchParams]);
  const ActionEnded = () => {
    setActionEnd(true);
  };
  return (
    <div className={`Acceptjoin ${className}`}>
      <div className={`logo-place`}>
        <img src={logoMbj} alt={"logo my-better-job"} />
      </div>
      {actionEnd ? (
        <div className={"content-end"}>
          <p className={`fini`}>
            Vous avez rejoins le portefeuille de votre conseiller
            <br /> vous pouvez fermer cette page
          </p>
        </div>
      ) : (
        <div className={`content-place`}>
          {PortefeuilleQuery.isLoading ? (
            <MbjLoading />
          ) : PortefeuilleQuery.isError ? (
            "Erreurs..."
          ) : !PortefeuilleQuery.data ? (
            <p>Cette page n'est plus d'actualité</p>
          ) : (
            <FormAcceptJoin Portefeuille={PortefeuilleQuery.data} ActionEnded={ActionEnded} />
          )}
        </div>
      )}
    </div>
  );
};

export const AcceptJoin = styled(AcceptJoinCtrl)((props) => ({
  background: props.theme.background1,
  padding: "1rem",
  minHeight: "100vh",
  height: "100%",
  ".content-end": {
    width: "50%",
    background: "white",
    padding: "1rem",
    borderRadius: "6px",
    margin: "20px auto",
    p: {
      textAlign: "center",
    },
  },
  ".logo-place": {
    textAlign: "center",
    marginTop: "20px",
    img: {
      width: "150px",
      height: "auto",
    },
  },
  ".content-place": {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));
