import React from "react";
import styled from "styled-components";
import { FlexContainerOptions, FlexItemOptions, useFlexContainer, useFlexItem } from "../../flex-utils";

interface MbjFlexCtrlProps extends FlexContainerOptions, FlexItemOptions {
  className?: string;

  children?: React.ReactNode;

  height?: React.CSSProperties["height"];
}
function MbjFlexCtrl(props: MbjFlexCtrlProps) {
  return <div className={props.className}>{props.children}</div>;
}

export const MbjFlex = styled(MbjFlexCtrl)((props) => ({
  display: "flex",
  height: props.height,
  ...useFlexContainer(props),
  ...useFlexItem(props),
}));
MbjFlex.defaultProps = {
  direction: "row",
  justify: "flex-start",
  gap: "7px",
  align: "stretch",
  wrap: "wrap",
};
