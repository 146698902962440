import * as React from "react";
import styled from "styled-components";
import {
  CustomSelect,
  MbjButton,
  MbjFlex,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import {
  Operation,
  useAddOffreInterne,
  useGetTypesContracts,
  useGetUserMetiers,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";

interface NouvelleOffreModalCtrlProps extends MbjModalCtrlProps {
  memberId: number;
}

function NouvelleOffreModalCtrl(props: NouvelleOffreModalCtrlProps) {
  const userMetiersQuery = useGetUserMetiers(props.memberId);
  const typeContractsQuery = useGetTypesContracts();
  const mutationAddOffre = useAddOffreInterne(props.memberId);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<Operation<"postOffreInterneCollection", "requestBody">>({
    mode: "onChange",
  });

  return (
    <MbjModal {...props} title="Création d'une offre" fitContent>
      {
        <form
          onSubmit={handleSubmit((data) => {
            console.log(data);
            mutationAddOffre.mutate({
              ...data,
              Member: `/api/members/${props.memberId}`,
              UserMetier: `/api/user_metiers/${data.UserMetier}`,
              TypeContrat: `/api/type_contrats/${data.TypeContrat}`,
              endAt: data.endAt === "" ? null : data.endAt,
            });
          })}
          className="Infos"
        >
          <MbjFlex direction={"column"} gap={"10px"}>
            <MbjSimpleInputContainer errors={errors} label={"Libelle de l'offre *"}>
              <MbjSimpleInput
                name={"libelle"}
                id={"libelle"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Libellé requis",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Métier exercé *"} htmlFor={"UserMetier"}>
              <Controller
                name={"UserMetier"}
                control={control}
                rules={{ required: { value: true, message: "Ce champ est requis" } }}
                render={({ field }) => (
                  <CustomSelect
                    inputId={"UserMetier"}
                    isLoading={userMetiersQuery.isLoading}
                    options={
                      userMetiersQuery.data
                        ? userMetiersQuery.data.map(function (um) {
                            return { id: um.id || 0, namePerso: um.namePerso || "" };
                          })
                        : undefined
                    }
                    getOptionLabel={(item) => item.namePerso || ""}
                    getOptionValue={(item) => item.id + ""}
                    isClearable={true}
                    onChange={(selectedOption, triggeredAction) => {
                      if (selectedOption) {
                        field.onChange(selectedOption.id);
                      }
                      if (triggeredAction.action === "clear") {
                        field.onChange(null);
                      }
                    }}
                  />
                )}
              />
            </MbjSimpleInputContainer>
            <MbjFlex>
              <MbjSimpleInputContainer errors={errors} label={"Date de début *"}>
                <MbjSimpleInput
                  name={"startAt"}
                  id={"startAt"}
                  type={"date"}
                  noPadding
                  register={register}
                  registerOptions={{ required: { value: true, message: "date début requise" } }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Date de fin"}>
                <MbjSimpleInput name={"endAt"} id={"endAt"} type={"date"} noPadding register={register} />
              </MbjSimpleInputContainer>
            </MbjFlex>
            <MbjSimpleInputContainer errors={errors} label={"Type du contrat *"} htmlFor={"idContrat"}>
              <Controller
                name={"TypeContrat"}
                control={control}
                rules={{ required: { value: true, message: "Contrat requis" } }}
                render={({ field }) => (
                  <CustomSelect
                    inputId={"idTypeContrat"}
                    isLoading={typeContractsQuery.isLoading}
                    options={typeContractsQuery.data}
                    getOptionLabel={(option) => option?.libelle || ""}
                    getOptionValue={(option) => option?.id + ""}
                    isClearable={true}
                    onChange={(selectedOption, triggeredAction) => {
                      if (selectedOption) {
                        field.onChange(selectedOption.id);
                      }
                      if (triggeredAction.action === "clear") {
                        field.onChange(null);
                      }
                    }}
                  />
                )}
              />
            </MbjSimpleInputContainer>
            <MbjFlex>
              <MbjSimpleInputContainer errors={errors} label={"Code Postal du poste"}>
                <MbjSimpleInput name={"codePostal"} id={"codePostal"} noPadding register={register} />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Salaire du poste"}>
                <MbjSimpleInput
                  name={"salaire"}
                  id={"salaire"}
                  noPadding
                  register={register}
                  type={"number"}
                  registerOptions={{
                    valueAsNumber: true,
                    min: { value: 0, message: "Le salaire ne peut pas être négatif" },
                  }}
                />
              </MbjSimpleInputContainer>
            </MbjFlex>
            <MbjSimpleInputContainer errors={errors} label={"Description du poste"}>
              <MbjSimpleTextArea
                name={`description`}
                id={`description`}
                noPadding
                register={register}
                height={"90px"}
                resize={"none"}
              />
            </MbjSimpleInputContainer>

            <MbjButton alignSelf={"flex-end"} isPending={mutationAddOffre.isLoading}>
              Créer l'offre
            </MbjButton>
          </MbjFlex>
        </form>
      }
    </MbjModal>
  );
}
export const NouvelleOffreModal = styled(NouvelleOffreModalCtrl)((props) => ({}));
