import React, { useState } from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjListItem,
  MbjLoading,
  MbjRange,
  MbjScrollArea,
  MbjSearchBar,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { GetElementType, useDebounce } from "@mbj-front-monorepo/utils";
import { useSearchActivites } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";

interface MbjFindActiviteCrtlProps {
  className?: string;
  CallBackFx: (args: { idActivite: number; niveau: number }) => void;
  isPending: boolean;
}

const MbjFindActiviteCtrl = (props: MbjFindActiviteCrtlProps) => {
  const [search, setSearch] = useState("");
  const [actiNiveau, setActiNiveau] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const { isLoading, isError, data: activites } = useSearchActivites(debouncedSearch);
  const [actiChoice, setActiChoice] = useState<GetElementType<typeof activites> | undefined>(undefined);

  const {
    register,
    formState: { errors },
  } = useForm();

  return (
    <div className={props.className}>
      {!actiChoice ? (
        <div className={"zoneSearch"}>
          <MbjSimpleInputContainer errors={errors} label={"Libellé de la compétence"} direction={"column"}>
            <MbjSearchBar
              id={"sarchComp"}
              placeholder={"Rechercher une compétence"}
              className={"SearchBar"}
              onChange={(event) => setSearch(event.target.value)}
            />
          </MbjSimpleInputContainer>
          <div className="resultats">
            {search === "" ? (
              <React.Fragment />
            ) : isLoading ? (
              <MbjLoading />
            ) : isError ? (
              "Errors"
            ) : activites.length === 0 ? (
              <p className="noResult">Aucun résultat trouvé pour "{search}"</p>
            ) : (
              <>
                <div>Résultats:</div>
                <MbjScrollArea maxHeight={"300px"}>
                  {activites?.map((activite, idx: number) => (
                    <MbjListItem className="one-choice-activite" onClick={() => setActiChoice(activite)} key={idx}>
                      {activite.libelle}
                    </MbjListItem>
                  ))}
                </MbjScrollArea>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={"zoneNiveau"}>
          <MbjButton
            leftIcon={<IoArrowBack />}
            onClick={() => setActiChoice(undefined)}
            themeColor={"secondary"}
            alignSelf={"flex-start"}
          >
            Retour
          </MbjButton>
          <div className={"libChoice"}>{actiChoice?.libelle}</div>
          <h6>Indiquez le niveau pour cette compétence</h6>
          <div className={"interact-container"}>
            <MbjRange nbPoints={5} currentPoint={0} onClick={(level) => setActiNiveau(level)} debounceTime={1} />
            <MbjButton
              themeColor={"primary"}
              size={"sm"}
              isPending={props.isPending}
              onClick={() => {
                props.CallBackFx({ idActivite: actiChoice?.id || -1, niveau: actiNiveau });
                console.log("ajout: ", actiChoice?.id, actiNiveau);
              }}
            >
              Ajouter la compétence
            </MbjButton>
          </div>
        </div>
      )}
    </div>
  );
};

export const MbjFindActivite = styled(MbjFindActiviteCtrl)((props) => ({
  position: "relative",

  ".zoneSearch": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    ".resultats": {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },

  ".zoneNiveau": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",

    ".libChoice": {
      fontSize: "18px",
      fontWeight: "bold",
    },
    ".interact-container": {
      display: "flex",
      flexDirection: "column",
      gap: "25px",

      button: {
        alignSelf: "flex-end",
      },
    },
  },
}));
