import * as React from "react";
import styled from "styled-components";

interface MbjStatsNumberCtrlProps {
  /**
   * Classe HTML ?
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'élément sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Titre de l'élément ?
   */
  label: string;
  /**
   * Valeur de la statistique ?
   */
  value?: string | number;
  /**
   * Augmentation de cette valeur (ex : 300%, 50%, ...)
   */
  increase?: string;
}

function MbjStatsNumberCtrl(props: MbjStatsNumberCtrlProps) {
  return (
    <div className={`${props.className}`}>
      <div className="labelStat">{props.label}</div>
      {props.value || props.value === 0 ? <div className="value">{props.value}</div> : <React.Fragment />}
      {props.increase ? <div className="increase">{props.increase}</div> : <React.Fragment />}
    </div>
  );
}
/**
 * Composant représentant une valeur statistiques (à mettre en avant)
 */
export const MbjStatsNumber = styled(MbjStatsNumberCtrl)((props) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  padding: "0.5rem",

  "& .labelStat": {
    width: "100%",
    fontSize: "14px",
    color: props.theme.grey,
    textAlign: "center",
    padding: "0.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "50%",
    borderBottom: `${props.theme.greyPastel} solid 1px`,
  },
  "& .value": {
    width: "100%",
    height: "50%",
    textAlign: "center",
    fontSize: "1.8em",
    color:
      props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : "none",
    fontWeight: "bold",
  },
  "& .increase": {
    color: props.theme.third,
    fontSize: "calc(9px + 0.9vmin)",
    marginTop: "5px",
  },
}));
MbjStatsNumber.defaultProps = {
  themeColor: "primary",
};
