import React from "react";
import styled from "styled-components";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MbjTooltipContent } from "../MbjTooltip/parts/MbjTooltipContent";
import { MbjTooltipArrow } from "../MbjTooltip/parts/MbjTooltipArrow";
import { MbjTooltip } from "../MbjTooltip/MbjTooltip";
import { MbjTooltipTrigger } from "../MbjTooltip/parts/MbjTooltipTrigger";
import { BaseColorsTheme } from "../../../assets/styles/ThemeType";

interface MbjInfoCtrlProps {
  /**
   * Classe HTML :
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * taille
   */
  size?: number;
  /**
   * Texte informatif
   */
  text?: string;
  children?: React.ReactNode;
  /**
   * Placement du texte
   */
  position?: "top" | "right" | "bottom" | "left";
}
function MbjInfoCtrl(props: MbjInfoCtrlProps) {
  return (
    <div className={props.className}>
      <MbjTooltip delayDuration={0}>
        <MbjTooltipTrigger asChild>
          <div>
            <BsFillInfoCircleFill className="icon" size={props.size} />
          </div>
        </MbjTooltipTrigger>
        <MbjTooltipContent sideOffset={5}>
          {props.text || props.children}
          <MbjTooltipArrow />
        </MbjTooltipContent>
      </MbjTooltip>
    </div>
  );
}

/**
 * Composant contenant une animation de "chargement"
 */
export const MbjInfo = styled(MbjInfoCtrl)((props) => ({
  display: "inline-flex",
  alignItems: "center",
  fontSize: "calc(14px + 1.2vmin)",

  "& .icon": {
    fill: props.themeColor ? props.theme[`${props.themeColor}Pastel`] : "none",
    "&:hover": {
      fill: props.themeColor ? props.theme[props.themeColor] : "none",
    },
  },
}));
MbjInfo.defaultProps = {
  size: 20,
  position: "left",
  themeColor: "primary",
};
