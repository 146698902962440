import React, { useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetEmployeesByMember } from "@mbj-front-monorepo/data-access";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjScrollArea } from "@mbj-front-monorepo/ui";

interface CollaborateursCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
}

const CollaborateursCardCtrl = (props: CollaborateursCardCtrlProps) => {
  const { id } = useParams();
  const EmployeesQuery = useGetEmployeesByMember(id ? parseInt(id) : undefined);

  const ListCollab = useMemo(() => {
    if (EmployeesQuery.isLoading || EmployeesQuery.isError || !EmployeesQuery.data) {
      return [];
    } else {
      return EmployeesQuery.data;
    }
  }, [EmployeesQuery.isLoading, EmployeesQuery.isError, EmployeesQuery.data]);

  return (
    <MbjCardClassic className={props.className} title={"Collaborateurs"} noPadding {...props}>
      {EmployeesQuery.isLoading ? (
        <MbjLoading />
      ) : EmployeesQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <MbjScrollArea>
          <table className={"table-employee"}>
            <tbody>
              {ListCollab.map((item, idx) => (
                <tr key={item.id}>
                  <td>
                    <div className={`roundMobi ${!item.isMobilite ? "no-mob" : "mob"}`} />
                  </td>
                  <td>
                    {item.prenom} {item.nom}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.UserMetier && item.UserMetier.namePerso ? item.UserMetier.namePerso : "nc"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const CollaborateursCard = styled(CollaborateursCardCtrl)((props) => ({
  width: "100%",
  ".roundMobi": {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    margin: "auto",
    "&.no-mob": {
      background: "#ccc",
    },
    "&.mob": {
      background: props.theme.thirdLighten,
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tr: {
      td: {
        fontSize: "14px",
        padding: "0.25rem",
        borderBottom: `solid ${props.theme.background1} 1px`,
      },
    },
  },
}));
