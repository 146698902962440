import * as React from "react";
import styled from "styled-components";
import { FlexContainerOptions, FlexItemOptions, useFlexContainer, useFlexItem } from "../../flex-utils";

export interface MbjCardCtrlProps extends FlexContainerOptions, FlexItemOptions {
  /**
   * Classe HTML de la card?
   */
  className?: string;

  noPadding?: boolean;

  variant?: "normal";
  /**
   * Contenu de la card ?
   */
  children?: React.ReactNode;
}

function MbjCardCtrl(props: MbjCardCtrlProps) {
  return <div className={props.className}>{props.children}</div>;
}
/**
 * Composant layout card :
 */
export const MbjCard = styled(MbjCardCtrl)<MbjCardCtrlProps>((props) => ({
  display: "flex",
  backgroundColor: props.theme.background2,
  boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  borderRadius: "0.4em",

  "& > .MbjCardHeader": {
    padding: "0.35rem 0.9rem",
    borderTopRightRadius: "0.4em",
    borderTopLeftRadius: "0.4em",
  },
  "& > .MbjCardBody": {
    padding: "0.35rem 0.9rem",
    flexGrow: 1,
  },
  "& > .MbjCardFooter": {
    padding: "0.35rem 0.7rem",
  },

  ...useFlexContainer(props),
  ...useFlexItem(props),
}));
MbjCard.defaultProps = {
  direction: "column",
  variant: "normal",
};
