import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useAddParticulierActivite, useGetParticulier } from "@mbj-front-monorepo/data-access";
import { MbjIconButton, MbjLoading, MbjModal, MbjNavPath, MbjWidgetClassic, useModal } from "@mbj-front-monorepo/ui";
import { GrAdd } from "react-icons/gr";
import { MbjFindActivite, MbjParticulierActiviteAccordion } from "@mbj-front-monorepo/data-ui";

interface CompetencesPartCtrlProps {
  className?: string;
}

const CompetencesPartCtrl = ({ className }: CompetencesPartCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const particulierQuery = useGetParticulier(id ? parseInt(id) : undefined);
  const { isShowing, toggle } = useModal();
  const mutationAdd = useAddParticulierActivite(particulierQuery.data?.id || -1);
  const HandleEndFindActi = (data: { idActivite: number; niveau: number }) => {
    mutationAdd.mutate(
      {
        Particulier: "/api/particuliers/" + particulierQuery.data?.id,
        niveau: data.niveau,
        Activite: "/api/activites/" + data.idActivite,
      },
      {
        onSuccess: () => {
          toggle();
        },
      }
    );
  };
  return (
    <div className={`partDash ${className}`}>
      {particulierQuery.isLoading ? (
        <MbjLoading />
      ) : particulierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
              },
              { index: 3, label: "Compétences" },
            ]}
          />
          <MbjWidgetClassic title={"Compétences"} bodyDirection={"column"} grow={1} bodyGrow={1}>
            <MbjIconButton
              onClick={toggle}
              ariaLabel={"Ajouter une compétence"}
              icon={<GrAdd />}
              themeColor={"third"}
              alignSelf={"flex-end"}
              isRound
            />

            <MbjParticulierActiviteAccordion particulierId={id ? parseInt(id) : -1} themeColor={"grey"} />
          </MbjWidgetClassic>
        </>
      )}
      <MbjModal hide={toggle} isShowing={isShowing} title="Ajout d'une compétence">
        <MbjFindActivite CallBackFx={HandleEndFindActi} isPending={mutationAdd.isLoading} />
      </MbjModal>
    </div>
  );
};

export const CompetencesPart = styled(CompetencesPartCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex !important",
  flexDirection: "column",

  ".btn-add": {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    background: props.theme.thirdLighten,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    svg: {
      margin: "auto",
    },
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(95%)",
    },
  },
}));
