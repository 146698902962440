import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetParticulierMe = () => {
  const promise = () => fetchApi("/api/particuliers/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["particulier", "me"], promise);
};

export const useGetParticulier = (particulierId?: number) => {
  const promise = () => fetchApi("/api/particuliers/{id}", { method: "get", params: { id: particulierId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["particulier", { particulierId: particulierId }],
    promise,
    {
      enabled: !!particulierId,
    }
  );
};

export const useMutateParticulier = (particulierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putParticulierItem", "requestBody">) => {
      return fetchApi("/api/particuliers/{id}", { method: "put", json: data, params: { id: particulierId + "" } });
    },
    {
      onSuccess: (newParticulier) => {
        queryClient.invalidateQueries(["particulier"]);
        queryClient.setQueryData(["particulier", newParticulier.id], newParticulier);
        toast.success("Profil modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddParticulier = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postParticulierCollection", "requestBody">) => {
      return fetchApi("/api/particuliers", {
        method: "post",
        json: { ...data },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["particulier"]);
        queryClient.invalidateQueries(["portefeuille"]);
        toast.success("Invitation  envoyée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetParticulierCompetences = (particulierId: number | undefined) => {
  const promise = () =>
    fetchApi("/api/particulier_competences", { method: "get", query: { Particulier: particulierId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["particulier_competences", { particulierId: particulierId }],
    promise,
    {
      enabled: !!particulierId,
    }
  );
};

export const useAddParticulierActivite = (particulierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postParticulierActiviteCollection", "requestBody">) => {
      return fetchApi("/api/particulier_activites", { method: "post", json: data });
    },
    {
      onSuccess: (newEmplActi) => {
        queryClient.invalidateQueries(["particulier_competences", { particulierId }]);
        queryClient.setQueryData(["particulier_competences", newEmplActi.id], newEmplActi);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetXpPart = (particulierId?: number) => {
  const promise = () => fetchApi("/api/particulier_xps", { method: "get", query: { Particulier: particulierId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["experiences_part", { particulierId: particulierId }],
    promise,
    {
      enabled: !!particulierId,
    }
  );
};

export const useAddXpPart = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postParticulierXpCollection", "requestBody">) => {
      return fetchApi("/api/particulier_xps", { method: "post", json: data });
    },
    {
      onSuccess: (newXp) => {
        queryClient.invalidateQueries(["experiences"]);
        queryClient.setQueryData(["experiences_part", newXp.id], newXp);
        toast.success("Expérience ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteXpPart = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (particulierId: number) => {
      return fetchApi("/api/particulier_xps/{id}", { method: "delete", params: { id: particulierId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["experiences_part"]);
        toast.success("Expérience supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateXpPart = (particulierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putParticulierXpItem", "requestBody">) => {
      return fetchApi("/api/particulier_xps/{id}", { method: "put", json: data, params: { id: particulierId + "" } });
    },
    {
      onSuccess: (newExperience) => {
        queryClient.invalidateQueries(["experiences_part"]);
        queryClient.setQueryData(["experiences_part", newExperience.id], newExperience);
        toast.success("Expérience modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFiPart = (particulierId?: number) => {
  const promise = () => fetchApi("/api/particulier_fis", { method: "get", query: { Particulier: particulierId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["fis_part", { particulierId: particulierId }], promise, {
    enabled: !!particulierId,
  });
};

export const useGetPartMob = (particulierId: number | undefined) => {
  const promise = () =>
    fetchApi("/api/particulier_mobilites", { method: "get", query: { Particulier: particulierId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["mobilites_part", { particulierId: particulierId }],
    promise,
    {
      enabled: !!particulierId,
    }
  );
};

export const useGetOnePartMob = (idMob: number | undefined, particulierId: number | undefined) => {
  const promise = () => fetchApi("/api/particulier_mobilites/{id}", { method: "get", params: { id: idMob + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["mobilites_part_metier", { particulierId: particulierId, idMob }],
    promise,
    {
      enabled: !!particulierId && !!idMob,
    }
  );
};

export const useGetPartMobMetier = (particulierId: number | undefined, idMetier: number | undefined) => {
  const promise = () =>
    fetchApi("/api/particulier_mobilites", {
      method: "get",
      query: { Particulier: particulierId || -1, Metier: idMetier || -1, Refresh: 1 },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["mobilites_part", { particulierId: particulierId, idMetier }],
    promise,
    {
      enabled: !!particulierId && !!idMetier,
    }
  );
};

export const useGetConsult = (particulierId: number | undefined) => {
  const promise = () =>
    fetchApi("/api/conseiller_mobilite_consults", { method: "get", query: { Particulier: particulierId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["consultation", { particulierId: particulierId }],
    promise,
    {
      enabled: !!particulierId,
    }
  );
};

export const useAddFiPart = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postParticulierFiCollection", "requestBody">) => {
      return fetchApi("/api/particulier_fis", { method: "post", json: data });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["fis_part"]);
        queryClient.setQueryData(["fis_part", newFi.id], newFi);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFiPart = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (particulierId: number) => {
      return fetchApi("/api/particulier_fis/{id}", { method: "delete", params: { id: particulierId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fis_part"]);
        toast.success("formation supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateFiPart = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putParticulierFiItem", "requestBody">) => {
      return fetchApi("/api/particulier_fis/{id}", { method: "put", json: data, params: { id: id + "" } });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["fis_part"]);
        queryClient.setQueryData(["fis_part", newFi.id], newFi);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useUpdateNiveauActivitePart = (particulierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { niveau: number; idParticulierActivite: number }) => {
      return fetchApi("/api/particulier_activites/{id}", {
        method: "put",
        params: { id: data.idParticulierActivite + "" },
        json: data,
      });
    },
    {
      onSuccess: (newEmplActi) => {
        queryClient.invalidateQueries(["particulier_competences", { particulierId: particulierId }]);
        queryClient.setQueryData(["activites_particulier", newEmplActi.id], newEmplActi);
        toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActivitePart = (particulierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: number) => {
      return fetchApi("/api/particulier_activites/{id}", { method: "delete", params: { id: id + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["particulier_competences", { particulierId: particulierId }]);
        queryClient.invalidateQueries(["activites_particulier"]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useSaveConseillerConsult = (particulierMobiliteId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return fetchApi("/api/conseiller_mobilite_consults", {
        method: "post",
        json: { ParticulierMobilite: "/api/partuculier_mobilites/" + particulierMobiliteId },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["consultation"]);
      },
      onError: (error: any) => {
        //toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
