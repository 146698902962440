import React, { useMemo } from "react";
import styled from "styled-components";
import {
  MbjFlex,
  MbjIconButton,
  MbjLoading,
  MbjModal,
  MbjNavPath,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";
import { useGetPortefeuille } from "@mbj-front-monorepo/data-access";
import { PersonneSuivieCard } from "./PersonneSuivieCard";
import { GrAdd } from "react-icons/gr";
import { PersonneWait } from "./PersonneWait";
import { FormNewInvitation } from "../InvitationPortefeuille/FormNewInvitation";

interface MesPersonnesSuiviesCtrlProps {
  className?: string;
}

const MesPersonnesSuiviesCtrl = ({ className }: MesPersonnesSuiviesCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const PortefeuilleQuery = useGetPortefeuille();
  const PortefeuilleList = useMemo(() => {
    if (!PortefeuilleQuery.data) {
      return [];
    } else {
      return PortefeuilleQuery.data.filter((p) => !!p.acceptAt);
    }
  }, [PortefeuilleQuery.data]);
  const PortefeuilleWait = useMemo(() => {
    if (!PortefeuilleQuery.data) {
      return [];
    } else {
      return PortefeuilleQuery.data.filter((p) => !p.acceptAt);
    }
  }, [PortefeuilleQuery.data]);
  const location = useLocation();
  const handleClickAdd = () => {
    toggle();
  };
  return (
    <div className={className}>
      <MbjNavPath
        routePath={location.pathname}
        noMargin
        changeLabelTab={[
          {
            index: 1,
            label: "Personnes suivies",
          },
        ]}
      />
      <MbjFlex direction={"column"} gap={"15px"}>
        <MbjWidgetClassic title={"Personnes suivies"} bodyDirection={"column"}>
          <MbjIconButton
            onClick={handleClickAdd}
            ariaLabel={"Ajouter une personne"}
            icon={<GrAdd />}
            themeColor={"third"}
            alignSelf={"flex-end"}
            isRound
          />

          {PortefeuilleQuery.isLoading ? (
            <MbjLoading />
          ) : (
            <MbjFlex>
              {PortefeuilleList.map((item, idx) => (
                <PersonneSuivieCard key={item.id} Portefeuille={item} />
              ))}
            </MbjFlex>
          )}
        </MbjWidgetClassic>
        <MbjWidgetClassic title={"Personnes en attente"}>
          {PortefeuilleQuery.isLoading ? (
            <MbjLoading />
          ) : (
            <MbjFlex>
              {PortefeuilleWait.map((item, idx) => (
                <PersonneWait key={item.id} Portefeuille={item} />
              ))}
            </MbjFlex>
          )}
        </MbjWidgetClassic>
      </MbjFlex>
      <MbjModal isShowing={isShowing} title={"Inviter une personne"} hide={toggle}>
        <FormNewInvitation />
      </MbjModal>
    </div>
  );
};

export const MesPersonnesSuivies = styled(MesPersonnesSuiviesCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
}));
