import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetPropositionOffre = (propositionOffre?: number) => {
  const promise = () =>
    fetchApi("/api/proposition_offres/{id}", { method: "get", params: { id: propositionOffre + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["propositionOffres", propositionOffre], promise, {
    enabled: !!propositionOffre,
  });
};

export const useMutatePropositionOffre = (propositionOffre: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putPropositionOffreItem", "requestBody">) => {
      return fetchApi("/api/proposition_offres/{id}", {
        method: "put",
        params: { id: propositionOffre + "" },
        json: data,
      });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(["proposition_offres"]);
        toast.success("Réponse envoyée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddPropositionOffre = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postPropositionOffreCollection", "requestBody">) => {
      return fetchApi("/api/proposition_offres", {
        method: "post",
        json: data,
      });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(["proposition_offres"]);
        toast.success("Offre envoyée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
