import React, { useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetOffreInterneByMembre } from "@mbj-front-monorepo/data-access";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading } from "@mbj-front-monorepo/ui";

interface OffresCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
}

const OffresCardCtrl = (props: OffresCardCtrlProps) => {
  const { id } = useParams();
  const OffresQuery = useGetOffreInterneByMembre(id ? parseInt(id) : undefined);

  const ListOffres = useMemo(() => {
    if (OffresQuery.isError || OffresQuery.isLoading || !OffresQuery.data) {
      return [];
    } else {
      return OffresQuery.data;
    }
  }, [OffresQuery.isError, OffresQuery.isLoading, OffresQuery.data]);

  return (
    <MbjCardClassic title={"Offres"} className={props.className} noPadding {...props}>
      {OffresQuery.isLoading ? (
        <MbjLoading />
      ) : OffresQuery.isError ? (
        "Erreurs"
      ) : (
        <table className={"table-employee"}>
          <tbody>
            {ListOffres.map((item, idx) => (
              <tr key={item.id}>
                <td>
                  <div className={`roundMobi ${item.isDiffusable ? "diffusable" : "no-diffusable"}`} />
                </td>
                <td>{item.libelle}</td>
                <td>{item.startAt ? new Date(item.startAt).toLocaleDateString() : "nc"}</td>
                <td>{item.endAt ? new Date(item.endAt).toLocaleDateString() : "nc"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MbjCardClassic>
  );
};
export const OffresCard = styled(OffresCardCtrl)((props) => ({
  width: "100%",
  ".roundMobi": {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    margin: "auto",
    "&.no-diffusable": {
      background: "#ccc",
    },
    "&.diffusable": {
      background: props.theme.thirdLighten,
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tr: {
      td: {
        fontSize: "14px",
        padding: "0.25rem",
        borderBottom: `solid ${props.theme.background1} 1px`,
      },
    },
  },
}));
