import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useGetOffreInternePart } from "@mbj-front-monorepo/data-access";
import { MbjButton, MbjLoading, MbjNoResult, MbjScrollArea, useModal } from "@mbj-front-monorepo/ui";
import { MbjOffreInterneItem } from "@mbj-front-monorepo/data-ui";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { ProposeOffreInterneModal } from "./ProposeOffreInterneModal";

interface OffresInternesCtrlProps {
  className?: string;

  particulierId: number;
}

const OffresInternesCtrl = (props: OffresInternesCtrlProps) => {
  const offreInternesQuery = useGetOffreInternePart(props.particulierId);

  const { isShowing, toggle } = useModal();
  const [OffreInterne, setOffreInterne] = useState<GetElementType<typeof offreInternesQuery.data>>();

  const ListOffresFiltered = useMemo(() => {
    if (offreInternesQuery.isError || offreInternesQuery.isLoading || !offreInternesQuery.data) {
      return [];
    } else {
      return offreInternesQuery.data;
    }
  }, [offreInternesQuery.isError, offreInternesQuery.isLoading, offreInternesQuery.data]);

  return (
    <div className={props.className}>
      {offreInternesQuery.isLoading ? (
        <MbjLoading />
      ) : offreInternesQuery.isError ? (
        <p>Erreur ...</p>
      ) : ListOffresFiltered.length === 0 ? (
        <MbjNoResult text={"Aucune offre"} />
      ) : (
        <>
          <MbjScrollArea>
            {ListOffresFiltered.map((item, idx) => (
              <MbjOffreInterneItem
                key={item.id}
                OffreInterne={item}
                callToAction={
                  <MbjButton
                    onClick={() => {
                      setOffreInterne(item);
                      toggle();
                    }}
                  >
                    Proposer l'offre
                  </MbjButton>
                }
              />
            ))}
          </MbjScrollArea>
          <ProposeOffreInterneModal
            isShowing={isShowing}
            hide={toggle}
            OffreInterne={OffreInterne}
            particulierId={props.particulierId}
          />
        </>
      )}
    </div>
  );
};

export const OffresInternes = styled(OffresInternesCtrl)((props) => ({
  height: "100%",
}));
