import React, { useState } from 'react';
import styled from 'styled-components';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

interface MbjOpenCloseDetailsCtrlProps {
  className?: string;
  text: string | null;
  children?: any;
  themeColor?: 'primary' | 'secondary' | 'third' | 'complementary' | 'complementary2' | 'warning';
  hidePrefixe?: boolean;
  fontSize?: string;
  defaultOpen?: boolean;
}

const MbjOpenCloseDetailsCtrl = ({ className, text, ...props }: MbjOpenCloseDetailsCtrlProps) => {
  const [isOpen, setIsOpen] = useState(props.defaultOpen);
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <div className={`Closetoggle ${className}`} onClick={() => toggle()}>
        <span className="text-in">
          {props.hidePrefixe === undefined && (isOpen ? 'Masquer ' : 'Afficher ')}
          {text}
        </span>
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
      </div>
      {isOpen ? props.children : <React.Fragment />}
    </>
  );
};

export const MbjOpenCloseDetails = styled(MbjOpenCloseDetailsCtrl)((props) => ({
  //color: props.themeColor || "black",
  '.text-in': {
    marginRight: '0.5rem',
    fontSize: props.fontSize ? props.fontSize : '16px',
  },
  '&:hover': {
    cursor: 'pointer',
    '.text-in': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
}));
MbjOpenCloseDetails.defaultProps = {
  defaultOpen: false,
};
