import React from "react";
import "./ConseillerApp.scss";
import { logout } from "@mbj-front-monorepo/data-access";
import { MainDesktopLayout, MbjMenuLinks } from "@mbj-front-monorepo/ui";
import { MdGroups, MdOutlineDashboard } from "react-icons/md";
import { Navigate, Route, Routes } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { Dashboard } from "../../../pages/Dashboard/Dashboard";
import { NotFound } from "../../../pages/NotFound/NotFound";
import { CgProfile } from "react-icons/cg";
import { ProfilConseiller } from "../../../pages/Profil/ProfilConseiller";
import { EntreprisesSuivies } from "../../../pages/EntreprisesSuivies/EntreprisesSuivies";
import { GiFactory } from "react-icons/gi";
import { PersonnesSuivies } from "../../../pages/PersonnesSuivies/PersonnesSuivies";

export function ConseillerApp() {
  //const memberQuery = useGetMemberMe();

  const menuLinks: MbjMenuLinks[] = [
    {
      label: "Tableau de bord",
      to: "accueil",
      icon: <MdOutlineDashboard title="Tableau de bord icône" />,
    },
    { label: "Personnes suivies", to: "personne-suivies", icon: <MdGroups title="portefeuille icône" /> },
    { label: "Entreprises suivies", to: "entreprises-suivies", icon: <GiFactory title="portefeuille icône" /> },
    { label: "Mon profil", to: "profil", icon: <CgProfile title="Mon profil" /> },
  ];
  return (
    <div className="ConseillerApp">
      <Routes>
        <Route
          element={
            <MainDesktopLayout
              type="Conseiller"
              menuLinks={menuLinks}
              disconnectionCallback={logout}
              userLogo={<FaUserCircle className={"icon-profile-menu"} />}
            />
          }
        >
          <Route path="" element={<Navigate to="accueil" />} />
          <Route path="/accueil" element={<Dashboard />} />
          <Route path="/personne-suivies/*" element={<PersonnesSuivies />} />
          <Route path="/entreprises-suivies/*" element={<EntreprisesSuivies />} />
          <Route path="/profil" element={<ProfilConseiller />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
