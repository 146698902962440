import React, { useMemo } from "react";
import styled from "styled-components";
import { useGetPortefeuillePro } from "@mbj-front-monorepo/data-access";
import { useLocation } from "react-router-dom";
import { MbjFlex, MbjLoading, MbjNavPath, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { EntrepriseSuivieCard } from "./EntrepriseSuivieCard";
import { EntrepriseWait } from "./EntrepriseWait";

interface MesEntreprisesSuiviesCtrlProps {
  className?: string;
}

const MesEntreprisesSuiviesCtrl = ({ className }: MesEntreprisesSuiviesCtrlProps) => {
  const PortefeuilleProQuery = useGetPortefeuillePro();
  const PortefeuilleList = useMemo(() => {
    if (!PortefeuilleProQuery.data) {
      return [];
    } else {
      return PortefeuilleProQuery.data.filter((p) => !!p.acceptAt);
    }
  }, [PortefeuilleProQuery.data]);
  const PortefeuilleWait = useMemo(() => {
    if (!PortefeuilleProQuery.data) {
      return [];
    } else {
      return PortefeuilleProQuery.data.filter((p) => !p.acceptAt);
    }
  }, [PortefeuilleProQuery.data]);
  const location = useLocation();
  return (
    <div className={className}>
      <MbjNavPath
        routePath={location.pathname}
        changeLabelTab={[
          {
            index: 1,
            label: "Entreprises suivies",
          },
        ]}
      />
      <MbjWidgetClassic title={"Entreprise suivies"}>
        {PortefeuilleProQuery.isLoading ? (
          <MbjLoading />
        ) : (
          <MbjFlex grow={1}>
            {PortefeuilleList.map((item, idx) => (
              <EntrepriseSuivieCard key={item.id} EntrepriseSuivie={item} />
            ))}
          </MbjFlex>
        )}
      </MbjWidgetClassic>
      <MbjWidgetClassic title={"Entreprises en attente"}>
        {PortefeuilleProQuery.isLoading ? (
          <MbjLoading />
        ) : (
          <MbjFlex grow={1}>
            {PortefeuilleWait.map((item, idx) => (
              <EntrepriseWait key={item.id} Portefeuille={item} />
            ))}
          </MbjFlex>
        )}
      </MbjWidgetClassic>
    </div>
  );
};

export const MesEntreprisesSuivies = styled(MesEntreprisesSuiviesCtrl)((props) => ({
  padding: "1% 2%",
}));
