import React, { useMemo } from "react";
import styled from "styled-components";
import {
  components,
  Operation,
  useDeleteOffreInterne,
  useGetCompetencesUserMetiers,
} from "@mbj-front-monorepo/data-access";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  MbjCard,
  MbjCardBody,
  MbjCardFooter,
  MbjCardHeader,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface OffreCardCtrlProps {
  className?: string;
  OffreInterne: GetElementType<Operation<"getOffreInterneCollection">>;
}

interface ListActivite {
  niveauLib: string;
  activites: components["schemas"]["Competence-read.Competence"][];
}

const OffreCardCtrl = ({ className, OffreInterne }: OffreCardCtrlProps) => {
  const deleteOffre = useDeleteOffreInterne(OffreInterne.id);
  const navigate = useNavigate();
  const UserMetierCompQuery = useGetCompetencesUserMetiers(
    OffreInterne.UserMetier ? OffreInterne.UserMetier.id : undefined
  );
  const ListActivite: ListActivite[] = useMemo(() => {
    if (UserMetierCompQuery.isLoading || UserMetierCompQuery.isError || !UserMetierCompQuery.data) {
      return [];
    } else {
      const TabListActivite: ListActivite[] = [];
      const TabNiveauComps = UserMetierCompQuery.data.filter((c) => c.Competence !== undefined);
      const TabNiveau5: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau3: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau1: components["schemas"]["Competence-read.Competence"][] = [];
      UserMetierCompQuery.data.map((item) => {
        if (item && item.Competence && item.Niveau && item.Niveau >= 4) {
          TabNiveau5.push(item.Competence);
        } else if (item && item.Competence && item.Niveau && item.Niveau >= 2) {
          TabNiveau3.push(item.Competence);
        } else if (item && item.Competence && item.Niveau) {
          TabNiveau1.push(item.Competence);
        }
        return item;
      });
      TabListActivite.push({
        niveauLib: "Expert",
        activites: TabNiveau5,
      });
      TabListActivite.push({
        niveauLib: "Maitrise",
        activites: TabNiveau3,
      });
      TabListActivite.push({
        niveauLib: "Connaissances",
        activites: TabNiveau1,
      });
      return TabListActivite;
    }
  }, [UserMetierCompQuery.isLoading, UserMetierCompQuery.isError, UserMetierCompQuery.data]);

  const handleClickEdit = () => {
    if (OffreInterne.id) navigate(`${OffreInterne.id}`);
  };
  return (
    <MbjCard className={className}>
      <MbjCardHeader>
        <MbjHeading>
          {OffreInterne.libelle} - {OffreInterne.UserMetier?.Metier?.libelle}
        </MbjHeading>
      </MbjCardHeader>
      <MbjCardBody>
        <MbjFlex direction={"column"} gap={"10px"}>
          <div className={"entreprise-place"}>{OffreInterne.Member ? OffreInterne.Member.name : "inconnue"}</div>
          <div className={`location-lib`}>{OffreInterne.codePostal ? OffreInterne.codePostal : ""}</div>
          <MbjScrollArea maxHeight={"150px"} className={"activites-place"}>
            {ListActivite.map((item: ListActivite, idx) => (
              <div className={`blockConnaissances`} key={idx}>
                <div className={`niveau-connaissance`}>{item.niveauLib}</div>
                <div className={`list-comps`}>
                  <ul>
                    {item.activites.map((acti, idxA: number) => (
                      <li key={acti.id}>{acti.libelle}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </MbjScrollArea>
          <div className={`description-place`}>{OffreInterne.description ? OffreInterne.description : ""}</div>
        </MbjFlex>
      </MbjCardBody>
      <MbjCardFooter>
        <MbjFlex justify={"flex-end"}>
          <MbjIconButton onClick={handleClickEdit} ariaLabel={"Editer l'offre"} icon={<FiEdit />} size={"sm"} isRound />
          <MbjIconButton
            onClick={() => {
              deleteOffre.mutate();
            }}
            ariaLabel={"Supprimer l'offre"}
            isPending={deleteOffre.isLoading}
            icon={<BsTrash />}
            size={"sm"}
            isRound
            themeColor={"warning"}
          />
        </MbjFlex>
      </MbjCardFooter>
    </MbjCard>
  );
};

export const OffreCard = styled(OffreCardCtrl)((props) => ({
  ".activites-place": {
    ".blockConnaissances": {
      marginBottom: "10px",
      ".niveau-connaissance": {
        fontWeight: "bold",
        textDecoration: "underline",
        marginBottom: "7px",
      },
      ".list-comps": {
        paddingLeft: "30px",
      },
    },
  },

  ".entreprise-place": {
    color: props.theme.complementaryLighten,
    fontWeight: "bold",
  },
  ".location-lib": {
    color: props.theme.complementaryDarken,
    fontWeight: "bold",
  },
}));
